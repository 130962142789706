<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>Revise</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <h1 style="text-align: center">Coming soon</h1>
      <!-- <folder-container :items="subjects" />
      <ion-item-divider style="min-height: 50px;" />
      <book-marks /> -->
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonMenuButton,
  IonItemDivider,
} from "@ionic/vue";
import { pin, walk, warning, wifi, wine, ellipsisHorizontalSharp, arrowForward } from 'ionicons/icons';
import {mapGetters, mapActions} from 'vuex'
import FolderContainer from '../components/FolderContainer.vue';
import { REVISE } from '../constants/folders'
import BookMarks from '../components/BookMarks.vue';

export default {
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButtons,
    IonMenuButton,
    // IonItemDivider,
    // FolderContainer,
    // BookMarks 
  },
  setup() {
    return {
      pin, walk, warning, wifi, wine, ellipsisHorizontalSharp, arrowForward
    };
  },
  data() {
    return {
      subjects: []
    }
  },
  computed: {
  },
  methods: {
    ...mapActions(['getItems']),
  },
  async mounted() {
    let subjects = await this.getItems({ids: REVISE.map(it=> it.id)})
    subjects = subjects.map(it=>({
      ...it,
      href: `/${it.id}/topics`,
      subtitle: it.comingSoon?'Coming soon':`${it.child?.length} items`
    }))
    this.subjects = subjects
  }
};
</script>

<style lang="scss" scoped>
</style>