<template>
  <ion-page class="exam-item">
		<div class='interface-holder'>
			<div :class="`content ${isRightSliderOpen?'':'full'} ${isMobile?'mobile':'desktop'}`">
				<div class='left'>
					<div class='question-info'>
						<span v-if="!isMobile" style="margin-right: 10px; white-space: nowrap">Section | </span>
						<ion-select
							v-if="isMobile"
							interface="action-sheet"
							:value="currentSection"
							@ionChange="setSection({section_id: $event.target.value})">
							<ion-select-option v-for="section in sections" :key="section.id" :value="section.id">{{section.name}}</ion-select-option>
						</ion-select>
						<div class="sections" v-else>
							<button v-for="section in sections" :key="section.id"
								@click="setSection({section_id: section.id})"
								:class="`${section.id ==currentSection?'green':''}`">{{section.name}}</button>
						</div>
						<div></div>
						<ion-select 
							style="text-transform: capitalize; z-index: 0"
							:value="language_id" 
							@ionChange="changeLanguage({language_id: $event.target.value})">
							<ion-select-option v-for="l in availableLanguages" :key="l.id" :value="l.id">{{l.name}}</ion-select-option>
						</ion-select>
						<span class='mark-holder'>{{isMobile?'':'Mark: '}}<span>{{examDetail.exam_type == 'exam_rrb_ntpc'?1:2.0}} /</span> <span style="color: red">{{examDetail.exam_type == 'exam_rrb_ntpc'?-0.33:-0.5}}</span></span>
					</div>
					<div :class="`question-container text-size-${textSize} ${isMobile?'mobile':'desktop'}`">
						<div v-if="processedQuestion.comprehension_id" v-html="processedQuestion.comprehension" class='comprehension-container'></div>
						<div>
							<div class='question'>
								<span style="margin-bottom: 5px; font-size: 0.8em">Question {{current}} </span>
								<div class='question-item' v-html="processedQuestion.question"></div>
							</div>
							<ion-radio-group :value="selectedAnswer" @ionChange="setAnswer({id: question.id, answer: $event.detail.value})">
								<ion-item lines="none">
									<ion-label><span v-html="processedQuestion.a"></span></ion-label>
									<ion-radio slot="start" value="1"></ion-radio>
								</ion-item>

								<ion-item lines="none">
									<ion-label><span v-html="processedQuestion.b"></span></ion-label>
									<ion-radio slot="start" value="2"></ion-radio>
								</ion-item>

								<ion-item lines="none">
									<ion-label><span v-html="processedQuestion.c"></span></ion-label>
									<ion-radio slot="start" value="3"></ion-radio>
								</ion-item>

								<ion-item lines="none">
									<ion-label><span v-html="processedQuestion.d"></span></ion-label>
									<ion-radio slot="start" value="4"></ion-radio>
								</ion-item>
							</ion-radio-group>
						</div>

					</div>
				</div>
			</div>
		</div>
  </ion-page>
</template>

<script>
import {
  IonItem,
  IonLabel,
	IonRadio,
	IonRadioGroup,
	isPlatform,
	IonSelect,
	IonSelectOption,
	IonPage
} from "@ionic/vue";
import {mapGetters, mapActions, mapState} from 'vuex'
import { close, person } from 'ionicons/icons';
import { replaceImageNameWithURL } from '../../services/question'
export default {
  components: {
		IonPage,
		IonSelect,
		IonSelectOption,
		IonRadio,
		IonRadioGroup,
    IonItem,
    IonLabel,
  },
  setup() {
    return {
      close, person
    };
  },
  data() {
    return {
      isMobile: isPlatform('mobile'),
			examDetail: {
				exam_type: 'exam_rrb_ntpc'
			},
    }
  },
  computed: {
    ...mapGetters(['quiz']),
		...mapState({
      isRightSliderOpen: state=> state.exam.isRightSliderOpen,
      answers: state=> state.exam.answers,
			availableLanguages: state => state.exam.availableLanguages,
			language_id: state => state.exam.language_id,
			sections: state => state.exam.sections,
			currentSection: state => state.exam.currentSection,
			sessionId: state => state.exam.session_id,
			textSize: state => state.exam.textSize
		}),
    id() {
      return this.$route.params.quizId
    },
    current() {
      return this.$route.query.q || '1'
    },
    baseUrl() {
      return `https://storage.googleapis.com/exam-dangal-storage/questions/${this.question.id}`
    },
    processedQuestion() {
      let base = this.baseUrl
      let q = Object.assign({}, this.question)
      q.question = replaceImageNameWithURL(q.question||'', base)
      q.a = replaceImageNameWithURL(q.a||'', base)
      q.b = replaceImageNameWithURL(q.b||'', base)
      q.c = replaceImageNameWithURL(q.c||'', base)
      q.d = replaceImageNameWithURL(q.d||'', base)
      q.comprehension = replaceImageNameWithURL(q.comprehension||'', base)
      return q
    },
    question() {
      let q = this.quiz.questions?.[this.current - 1] || {translations: []}
      q = JSON.parse(JSON.stringify(q))
      let tr = q.translations.find(tr=> tr.language_id == this.language_id)
      if(!tr) {
        tr = q.translations[0]
      }
      if(!tr) {
        return {}
      }
      let comprehension = q.comprehension?.translations?.find(tr=> tr.language_id == this.language_id)?.question
      
      let {question, explanation, a, b, c, d} = tr
      Object.assign(q, {question, explanation, a, b, c, d, comprehension})
			this.visit({id: q.id})
			return q
    },
		selectedAnswer() {
			return this.answers[this.question.id] ||''
		},
  },
  watch: {
    processedQuestion: {
      handler() {
				setTimeout(()=> {
					try{
							window.MathJax.typeset();
					}catch(e){console.log(e)}
				}, 50)
      }
    },
		selectedAnswer: {
			handler() {
				console.log(this.selectedAnswer)
			},
			immediate: true
		}
  },
  methods: {
		...mapActions(['toggleNavBlock']),
    ...mapActions({
      visit: 'exam/visit',
      removeBookmark: 'exam/removeBookmark',
      clearAnswer: 'exam/clearAnswer',
      setAnswer: 'exam/setAnswer',
			changeLanguage: 'exam/changeLanguage',
			setSection: 'exam/setSection',
			submitSession: 'exam/submitSession'
    }),
    getClass(option) {
      if(!this.selected) {
        return ''
      }
      if(!this.shouldShowCorrectAnswer) {
        if(this.selected == option) {
          return 'selected'
        }
        return ''
      }
      if(this.selected == option && this.question.answer == option) {
        return 'correct'
      }
      if(this.selected == option && this.question.answer !== option) {
        return 'incorrect'
      }
      if(this.question.answer == option) {
        return 'correct'
      }
      return ''
    },
  }
}
</script>

<style lang="scss" scoped>
.exam-item {
	// .interface-page{
		display: flex;
		justify-content: center;
		flex-direction: column;
		height: calc(100% - 50px);

		ion-item {
			z-index: 0;
		}
		ion-radio{
			margin-inline-end: 15px
		}
		ion-label {
			text-overflow: unset;
			white-space: inherit;
		}

		button{
			margin: 0 10px;
			background-color: white;
			outline: none;
			border: solid 1px gray;
			border-radius: 3px;
			min-width: 50px;
			cursor: pointer;
			color: black;
			font-size: 14px;

			&:disabled {
				opacity: 0.5;
			}
			&:first-child{
				margin-left: unset;
			}
			&:last-child{
				margin-right: unset;
			}
		}
		.interface-holder{
			display: flex;
			flex-direction: column;
			height: 100%;

			.content {
				display: flex;
				flex: 1;
				flex-direction: row;
				overflow-x: hidden;
				position: relative;

				&.full {
					.left {
						width: 100vw;
					}
				}

				&.mobile {
					.left {
						width: 100vw;
					}
					&.full {
						.right {
							right: -80.5vw;
							box-shadow: 0vw 0 10vw -10px rgba(100,100,100, 0.3);
						}
					}
					.right {
						width: 80vw;
						background-color: white;
						box-shadow: -20vw 0 10vw 0px rgba(100,100,100, 0.4);
					}
				}

				.left {
					font-size: 0.9em;
					display: flex;
					flex-direction: column;
					width: 75vw;
					transition: width 0.4s ease-in;

					.question-container{
						display: flex;
						flex-direction: row;
						flex: 1;
						height: calc(100vh - 190px);

						&>div{
							flex: 1;
							height: calc(100vh - 225px);
							overflow-y: scroll;
							padding-bottom: 10px;
						}
						.comprehension-container{
							flex: 1;
							padding: 10px;
							padding-top: 25px;
							border-right: 1px solid #d1d1d1;
							text-align: justify;
							max-width: 40vw;
							line-height: 1.5em;

							strong{
								display: block;
							}
						}
						&.mobile{
							flex-direction: column;
							.comprehension-container {
								max-width: unset;
								border-right: none;
								border-bottom: 1px solid #ccc;
							}
							&.text-size-1{
								font-size: 13px;
							}
							&.text-size-2{
								font-size: 14px;
							}
							&.text-size-3{
								font-size: 15px;
							}
							&.text-size-4{
								font-size: 16px;
							}
							&.text-size-5{
								font-size: 17px;
							}
							&.text-size-6{
								font-size: 19px;
							}

							.question {
								margin-bottom: 10px;
							}
						}
						&.text-size-1{
							font-size: 14px;
						}
						&.text-size-2{
							font-size: 16px;
						}
						&.text-size-3{
							font-size: 18px;
						}
						&.text-size-4{
							font-size: 20px;
						}
						&.text-size-5{
							font-size: 22px;
						}
						&.text-size-6{
							font-size: 25px;
						}
						

						.option-item {
							display: flex;
							flex-direction: row;
							padding: 10px 25px;
							align-items: center;
							margin-bottom: 5px;
							cursor: pointer;
							ion-checkbox{
								margin-right: 10px;
								z-index: 0;
							}
							&>span {
								display: flex;
								flex-direction: row;
								align-items: center;
							}
						}
					}

					.question-info {
						font-weight: bold;
						padding: 0px 10px;
						font-size: 0.8em;

						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						border-bottom: 1px solid rgba(105, 105, 167, 0.5);
						height: 40px;
						line-height: 20px;
						z-index: 0;

						&>div{
							display: flex;
							flex: 1;
							flex-direction: row;
							height: 100%;
						}
						button.green {
							background-color: #4e85c5;
							color: white;
							border: none;
						}
						button{
							margin: 0;
							display: flex;
							height: 100%;
							align-items: center;
							white-space: nowrap;
							border: none;
							border-radius: 0;
							border-right: solid 1px #ccc;
							font-size: 1em;
						}
						select {
							padding: 5px 10px;
							margin-right: 10px;
							outline: none;
						}
						.mark-holder{
							background-color: turquoise;
							padding: 5px 10px;
							font-size: 0.8em;
							border-radius: 3px;
						}
					}

					.question {
						white-space: pre-line;
						display: inline-grid;
						margin-bottom: 20px;
						/*background: rgba(255,255,255,0.6);*/
						padding: 10px 20px;
						line-height: 1.5em;
				
						i{
							font-weight: bold;
							font-size: 15px;
							/*background: rgba(255,145,255,0.6);*/
							padding: 3px 8px;
							color: rgba(30,30,30,0.7);
							position: relative;
							text-align: right;
							top: 8px;
							right: -5px;
						}
					}
					.question-option {
						line-height: 25px;
						position: relative;
						display: flex;
						flex-direction: row;
						padding-left: 0;
						margin-bottom: 10px;
						transition: background 0.15s ease-in-out;
						overflow: hidden;
						min-height: 40px;
						&>span {
							display: flex;
							align-items: center;
							flex: 1;
						}
						&>span:first-child{
							margin-right: 10px;
							font-weight: bold;
							background: rgba(40,40,40,0.8);
							color: white;
							width: 10vw;
							max-width: 50px;
							display: flex;
							align-items: center;
							justify-content: center;
							flex: none;
						}
					}
				}
			}
		}
}
</style>