import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { getFirestore, doc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import store from './store'


const firebaseConfig = {
  apiKey: "AIzaSyBJ7uDE5bMILI24nQlvdj4qMtsP_MaBRqs",
  authDomain: "aazumi.com",
  databaseURL: 'https://api-dashboard-d9a55.firebaseio.com',
  projectId: "api-dashboard-d9a55",
  storageBucket: "api-dashboard-d9a55.appspot.com",
  messagingSenderId: "609035513496",
  appId: "1:609035513496:web:73436db5cd3b4954bc2aed",
  measurementId: "G-ET9FQTN84B"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth()
const db = getFirestore(app);
const storage = getStorage();

// Get logged in user
export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user:any) => {
      store.dispatch("user/setUser", user)
      unsubscribe()
      resolve(user)
    }, reject)
  })
};
onAuthStateChanged(auth, async (user) => {
  store.dispatch("user/setUser", user)
})


const uploadFile = async (file:any, url:string) => {
  const storageRef = ref(storage, url);
  await uploadBytes(storageRef, file)
  return {url: await getDownloadURL(storageRef) }
}

export {
  db,
  app,
  auth,
  doc,
  uploadFile,
  getDownloadURL,
}