
export const INSTRUCTIONS = [
  {
    id: '2fc4040b-edc4-4168-bfc4-75943c203e8f',
    body: `
      Please note that this a Mock Test which is for <strong>practice purpose</strong>. Candidates are advised not to consider this as <strong>sample paper</strong>. The question provided in this Mock Test may have been previously asked in the Exam.
      
      <strong>Total No. of Questions and Duration of Test.</strong>
      <ul>
        <li>There are 100 nos. of Questions which are to be attempted in 60 minutes.</li>
        <li>Total test duration is 60 minutes.</li>
        <li>The test will finish automatically after 60 minuts</li>
      </ul>
      <strong>Marking Scheme</strong>
      <ul>
      <li>Total No. of Question: 100</li>
      <li>Marks for correct attempted question- +1</li>
      <li>Marks for wrong attempted question: -.33</li>
      <li>Please note there will no negative (-ve) marking for unattempted questions.</li>
      </ul>
    `
  },
  {
    id: '3f1614f8-60b7-409b-8252-95cbd0114852',
    body: `
      Please note that this a Mock Test which is for <strong>practice purpose</strong>. Candidates are advised not to consider this as <strong>sample paper</strong>. The question provided in this Mock Test may have been previously asked in the Exam.
      
      <strong>Total No. of Questions and Duration of Test.</strong>
      <ul>
        <li>There are 25 nos. of Questions which are to be attempted in 25 minutes.</li>
        <li>Total test duration is 25 minutes.</li>
        <li>The test will finish automatically after 25 minuts</li>
      </ul>
      <strong>Marking Scheme</strong>
      <ul>
        <li>Total No. of Question: 25</li>
        <li>Marks for correct attempted question- +1</li>
        <li>Marks for wrong attempted question: - 0.33</li>
        <li>Please note there will no negative (-ve) marking for unattempted questions.</li>
      </ul>
    `
  },
  {
    id: 'f30d92d4-f535-4fd4-bff4-d2b1b6dcf62c',
    body: `
      Please note that this a Mock Test which is for <strong>practice purpose</strong>. Candidates are advised not to consider this as <strong>sample paper</strong>. The question provided in this Mock Test may have been previously asked in the Exam.

      <strong>Total No. of Questions and Duration of Test.</strong>
      <ul>
        <li>There are 200 nos. of Questions which are to be attempted in 120 minutes.</li>
        <li>Total test duration is 120 minutes.</li>
        <li>The test will finish automatically after 120minuts</li>
      </ul>
      <strong>Marking Scheme</strong>
      <ul>
        <li>Total No. of Question: 200</li>
        <li>Marks for correct attempted question- +1</li>
        <li>Marks for wrong attempted question: -.33</li>
        <li>Please note there will no negative (-ve) marking for unattempted questions.</li>
      </ul>
    `
  }
]
export const getInstruction = (id:string) => {
  let i = INSTRUCTIONS.findIndex((ins: any)=> ins.id === id )
  if(i < 0 ) {
    i = 0
  }
  return INSTRUCTIONS[i].body
}
