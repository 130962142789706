
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  menuController,
  IonButtons,
  IonMenuButton,
  IonItemDivider
} from "@ionic/vue";
import { ellipse, square, menu } from "ionicons/icons";
import RecentlyCard from '../components/RecentlyCard.vue';
import StorySlider from '../components/StorySlider.vue';
import BannerCarousal from '../components/BannerCarousal.vue';
import RateUs from '../components/RateUs.vue';

export default {
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButtons,
    IonMenuButton,
    RecentlyCard,
    StorySlider,
    BannerCarousal,
    IonItemDivider,
    RateUs
  },
  setup() {
    return {
      ellipse,
      square,
      menu,
    };
  },
  mounted() {
    window.localStorage.removeItem('vuex')
  },
  methods: {
    openSideMenu() {
      menuController.enable(true, "sidemenu");
      menuController.open("sidemenu");
    },
  },
};
