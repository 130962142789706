<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :icon="close" default-href="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Flashcards</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card>
        <ion-card-header>
          <ion-card-title>Instructions</ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <ul style="padding-inline-start: 20px">
            <li>This feature helps the students to memorize the content and increase their speed.</li>
            <li>The student's feedback on every question is used to improve their learning.</li>
          </ul>
          <h2 style="color: var(--ion-color-step-850, #262626); font-weight: bold">Expected outcomes</h2>
          <ul style="padding-inline-start: 20px">
            <li>Enhance learning.</li>
            <li>Improve speed and accuracy.</li>
          </ul>

          <ion-button
            expand='block'
            @click="$router.replace(`/flashcard/running/${id}/`)">
            Start
          </ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent
} from "@ionic/vue";
import { close } from 'ionicons/icons';
import { mapActions} from 'vuex'
import { GET_BUNDLE } from '../../graphql/bundle';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent
  },
  setup() {
    return {
      close
    };
  },
  data() {
    return {
      quiz: {
        questions: []
      },
    }
  },
  computed: {
    id() {
      return this.$route.params.quizId
    }
  },
  mounted() {
    this.getBundle({id: this.id})
  },
  methods: {
    ...mapActions(['getBundle']),
  }
}
</script>
