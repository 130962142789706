<template>
  <ion-page class="question-item">
    <div class="question-text">
      <span style="font-weight: bold;">Question {{current}}</span>
      <p style="white-space: pre-wrap; line-height: 2em; font-weight: bold;" v-html="processedQuestion.question"></p>
    </div>
    <div class="option-holder">
      <div
        :class="getClass('1')"
        @click="setAnswer({id: processedQuestion.id, answer: '1'})">
        <span class="label">A</span>
        <p style="white-space: pre-wrap;" v-html="processedQuestion.a"></p>
      </div>
      <div
        :class="getClass('2')"
        @click="setAnswer({id: processedQuestion.id, answer: '2'})">
        <span class="label">B</span>
        <p style="white-space: pre-wrap;" v-html="processedQuestion.b"></p>
      </div>
      <div
        :class="getClass('3')"
        @click="setAnswer({id: processedQuestion.id, answer: '3'})">
        <span class="label">C</span>
        <p style="white-space: pre-wrap;" v-html="processedQuestion.c"></p>
      </div>
      <div
        :class="getClass('4')"
        @click="setAnswer({id: processedQuestion.id, answer: '4'})">
        <span class="label">D</span>
        <p style="white-space: pre-wrap;" v-html="processedQuestion.d"></p>
      </div>
    </div>
    <div @click="isModalOpen=true" style="text-decoration: underline">Report error</div>

    <div v-if="shouldShowCorrectAnswer && selected" class="question-text">
      <span style="font-weight: bold;">Explanation:</span>
      <p style="white-space: pre-wrap;" v-html="processedQuestion.explanation"></p>
    </div>

    <report-error
      v-model:isOpen="isModalOpen"
      itemType="QUESTION"
      :itemId="processedQuestion.id"
      description="If you find anything wrong with the questions, please report. This help us maintain the quality with our limited resources."
      buttonText="Report question" />

  </ion-page>
</template>

<script>
import {
	IonCheckbox,
  IonPage
} from "@ionic/vue";
import { mapActions, mapState, mapGetters } from 'vuex'
import { close } from 'ionicons/icons';
import { replaceImageNameWithURL } from '../../services/question'
import ReportError from '../../components/ReportError.vue';
export default {
  components: {
    ReportError,
    IonPage
  },
  setup() {
    return {
      close
    };
  },
  props: {
  },
  data() {
    return {
      shouldShowCorrectAnswer: false,
      isModalOpen: false,
    }
  },
  computed: {
    ...mapGetters(['quiz']),
		...mapState({
			availableLanguages: state => state.quiz.availableLanguages,
			language_id: state => state.quiz.language_id,
      answers: state=> state.quiz.answers,
		}),
    id() {
      return this.$route.params.quizId
    },
    current() {
      return this.$route.query.q || '1'
    },
    selected() {
      return this.answers[this.processedQuestion.id]
    },
    baseUrl() {
      return `https://storage.googleapis.com/exam-dangal-storage/questions/${this.question.id}`
    },
    question() {
      let q = this.quiz.questions?.[this.current - 1] || {translations: []}
      let tr = q.translations.find(tr=> tr.language_id == this.language_id)
      if(!tr) {
        tr = q.translations[0]
      }
      if(!tr) {
        return q
      }
      let {question, explanation, a, b, c, d} = tr
      Object.assign(q, {question, explanation, a, b, c, d})
      return q
    },
    processedQuestion() {
      let base = this.baseUrl
      let q = Object.assign({}, this.question)
      q.question = replaceImageNameWithURL(q.question||'', base)
      q.a = replaceImageNameWithURL(q.a||'', base)
      q.b = replaceImageNameWithURL(q.b||'', base)
      q.c = replaceImageNameWithURL(q.c||'', base)
      q.d = replaceImageNameWithURL(q.d||'', base)
      q.explanation = replaceImageNameWithURL(q.explanation||'', base)
      return q
    }
  },
  watch: {
    quiz: {
      handler() {
        // console.log(this.quiz)
      }
    },
    processedQuestion: {
      handler() {
        setTimeout(()=> {
          try{
              window.MathJax.typeset();
          }catch(e){console.log(e)}
        }, 50)
      }
    }
  },
  mounted() {
    this.getBundle({id: this.id})
		this.visit({id: this.question.id})
  },
  methods: {
    ...mapActions(['getBundle']),
    ...mapActions({
      visit: 'quiz/visit',
      clearAnswer: 'quiz/clearAnswer',
      setAnswer: 'quiz/setAnswer',
			changeLanguage: 'quiz/changeLanguage'
    }),
    getClass(option) {
      if(!this.selected) {
        return ''
      }
      if(!this.shouldShowCorrectAnswer) {
        if(this.selected == option) {
          return 'selected'
        }
        return ''
      }
      if(this.selected == option && this.question.answer == option) {
        return 'correct'
      }
      if(this.selected == option && this.question.answer !== option) {
        return 'incorrect'
      }
      if(this.question.answer == option) {
        return 'correct'
      }
      return ''
    },
  }
}
</script>

<style lang="scss" scoped>
.question-item {
  margin: 10px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  
  .question-text {
    display: flex;
    flex-direction: column;
    border: 1px solid #c4c4c4;
    padding: 5px;
    border-radius: 5px;
    gap: 5px;
    text-align: justify;
  }
  .option-holder {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &>div{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      transition: background 0.2s linear;
      overflow: hidden;

      &.correct {
        background: #27ae60;
      }
      &.incorrect {
        background: #e74c3c;
      }
      &.selected {
        background: #f3b478;
      }
      &:hover {
        // background: #eeeeee;
      }

      span.label {
        padding: 8px 12px;
        background: #3a3a3a;
        color: white;
      }
      ion-checkbox.label {
        margin: 8px 12px;
      }
    }
  }
  p {
    margin: 0;
  }
}
</style>