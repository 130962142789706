

export default {
  state () {
    return {
      theme: 'light',
      isNavBlocked: false,
      isAlterVisible: false,
    }
  },
  mutations: {
    SET_THEME(state:any, theme:any) {
      state.theme = theme
    },
    SET_NAV_BLOCK(state:any, isNavBlocked:boolean) {
      state.isNavBlocked = isNavBlocked
    },
    SET_ALERT_VISIBILITY(state:any, isAlertVisible:boolean) {
      state.isAlertVisible = isAlertVisible
    },
  },
  actions: {
    setTheme({ commit }:any, theme:string) {
      commit('SET_THEME', theme)
    },
    toggleNavBlock({commit}:any, {isBlocked=false}) {
      commit('SET_NAV_BLOCK', isBlocked)
    },
    toggleAlertVisiblity({commit}:any, {isAlertVisible=false}) {
      commit('SET_ALERT_VISIBILITY', isAlertVisible)
    }
  },
  getters: {
    theme (state:any) {
      return state.theme
    },
    isNavBlocked (state:any) {
      return state.isNavBlocked
    },
    isAlertVisible (state:any) {
      return state.isAlertVisible
    },
  },
}