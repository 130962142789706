<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :icon="close" default-href="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Exam Leaderboard</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <h2>Coming soon</h2>

        <div class="table-holder">
          <table>
            <thead>
              <tr>
                <td style="max-width: 30px">#</td>
                <td>Name</td>
                <td>Score</td>
                <!-- <td>Time(sec)</td> -->
              </tr>
            </thead>
            <tbody>
              <!-- <tr
                v-for="question, i in processedQuestions"
                @click="isExplanationOpen=true; selectedQuestion=question"
                :key="question.id">
                <td style="max-width: 30px">{{i+1}}</td>
                <td>{{question._question}}</td>
                <td :class="getStatusClass(question.id)"><ion-icon :icon="getStatus(question.id)" /></td>
              </tr> -->
            </tbody>
          </table>
        </div>
    </ion-content>
  </ion-page>
</template>

<script>

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonCard,
  IonIcon,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent
} from "@ionic/vue";
import { close, closeCircle, remove, checkmarkCircle } from 'ionicons/icons';
import { mapActions, mapGetters, mapState} from 'vuex'
import { GET_BUNDLE } from '../../graphql/bundle';
import { replaceImageNameWithURL } from '../../services/question'

function pad(v, n=2){
	v= v + ''
	return v.length >= n?v: new Array(n - v.length + 1).join('0') + v
}

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    // IonButton,
    IonBackButton,
    // IonCard,
    // IonIcon
    // IonCardHeader,
    // IonCardSubtitle,
    // IonCardContent
  },
  setup() {
    return {
      close, remove, checkmarkCircle, closeCircle
    };
  },
  data() {
    return {
      isExplanationOpen: false,
      selectedQuestion: {}
    }
  },
  computed: {
    ...mapGetters(['quiz']),
    ...mapGetters({
      attempted: 'exam/attempted',
      markedAndAnswered: 'exam/markedAndAnswered'
    }),
    ...mapState({
      bookmarks: state=> state.exam.bookmarks,
      answers: state=> state.exam.answers,
			availableLanguages: state => state.exam.availableLanguages,
			language_id: state => state.exam.language_id,
			sessionId: state => state.exam.session_id,
      totalQuestions: state => state.exam.totalQuestions,
      actions: state => state.exam.actions,
    }),
    id() {
      return this.$route.params.quizId
    },
    sessionId() {
      return this.$route.params.sessionId
    },
    baseUrl() {
      return `https://storage.googleapis.com/exam-dangal-storage/questions/`
    },
    processedQuestions() {
      let qs = this.quiz.questions
      qs = JSON.parse(JSON.stringify(qs))
      qs = qs.map(q=>{
        let base = `${this.baseUrl}${q.id}`
        let tr = q.translations.find(tr=> tr.language_id == this.language_id)
        if(!tr) {
          return {}
        }
        let {question="", a, b, c, d, explanation} = tr
        question = replaceImageNameWithURL(question||'', base)
        a = replaceImageNameWithURL(a||'', base)
        b = replaceImageNameWithURL(b||'', base)
        c = replaceImageNameWithURL(c||'', base)
        d = replaceImageNameWithURL(d||'', base)
        explanation = replaceImageNameWithURL(explanation||'', base)
        Object.assign(q, {question, _question: `${question.substring(0, 20)} ...`, a, b, c, d, explanation})
        return q
      })
      return qs
    },
    timeTaken() {
      let diff = (this.actions[this.actions.length - 1].created_at - this.actions[0].created_at)/1000
      diff = Math.floor(diff)
      let sec = diff%60
      diff = Math.floor(diff/60)
      let min = diff % 60;
      let hr = Math.floor(diff/60)
      return `${pad(hr)}:${pad(min)}:${pad(sec)}`
    },
    correctQuestions() {
      let correctAnswers = {}
      this.quiz.questions.forEach(q=>{
        correctAnswers[q.id] = q.answer
      })
      let correct = 0;
      for(let id in this.answers) {
        if(this.answers[id] == correctAnswers[id]) {
          correct ++
        }
      }
      return correct
    },
    accuracy() {
      return Math.round(10000 * this.correctQuestions/this.attempted.length)/100.0
    },
    score() {
      return Math.round(100*(1*this.correctQuestions - 0.33 * (this.attempted.length - this.correctQuestions)))/100.0
    }
  },
  watch: {
    quiz: {
      handler() {
        console.log(this.quiz)
      }
    }
  },
  mounted() {
    this.getBundle({id: this.id})
    this.restoreSession({id: this.id, sessionId: this.sessionId})
  },
  methods: {
    ...mapActions(['getBundle']),
    ...mapActions({
      restoreSession: 'exam/restoreSession',
      changeLanguage: 'exam/changeLanguage'
    }),
    getStatus(questionId) {
      const selected = this.answers[questionId]
      if(!selected) {
        return this.remove
      }
      let q = this.quiz.questions.find(_q=> _q.id == questionId)
      if(q.answer == selected) {
        return this.checkmarkCircle
      }
      return this.closeCircle
    },
    getStatusClass(questionId) {
      const selected = this.answers[questionId]
      if(!selected) {
        return ''
      }
      let q = this.quiz.questions.find(_q=> _q.id == questionId)
      if(q.answer == selected) {
        return 'correct'
      }
      return 'wrong'
    }
  }
}
</script>

<style lang="scss" scoped>
.table-holder {
  padding: 5px;
  display: flex;
}
table {
  flex: 1;
  margin: 10px 0px 5px 0px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;

  thead {
    background: #c4c4c4;
    padding: 5px;
    border-bottom: #a4a4a4;
  }
  tr{
    display: flex;
  }

  tbody {
    max-height: 50vh;
    overflow-y: scroll;
  }

  td {
    padding-top: 5px;
    flex: 1;

    &.correct {
      color: green;
    }
    &.wrong {
      color: red;
    }
  }
}

.left {
  height: unset;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
	background: #e5f6fd;
  gap: 5px;
  padding-top: 20px;
  div.item {
    display: flex;
    padding: 5px;
    flex-direction: column;
    border: 1px black solid;
		border: 1px black solid;

    .title {
      font-weight: bold;
      font-size: 1.2em;
    }
  }
  .openui-button{
    margin: 5px;
    border-radius: 5px;
    padding: 10px;
  }
}

</style>