<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar style="--background: #252525; color: white">
        <ion-title>Dashboard</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" style="--padding-top: 20px;--padding-start: 20px;--padding-end: 20px;">
      <div>
        <router-link to="/app-maker/function-file/create" class="clickable-item">
          <IonImg style="width: 30px" src="/assets/icon/add-file.png"></IonImg>
          <div style="flex: 1; display: grid; padding-left: 10px; font-size: 20px; gap: 5px;">
            <b>Create a firebase function file</b>
            <span style="font-size: 0.7em">Deploy and manage firebase function file with just few clicks</span>
          </div>
          <IonImg style="width: 40px; opacity: 0.9" src="/assets/icon/plus.png"></IonImg>
        </router-link>
      </div>
      <div style="margin-top: 30px">
        <ion-title>Create project</ion-title>
        <ion-item>
          <ion-label>Project name</ion-label>
          <ion-input v-model="newProjectName" placeholder="enter text" />
        </ion-item>
        <ion-button @click="createProject">Create</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="js">
import { addDoc, collection } from "@firebase/firestore";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  menuController,
  IonImg,
  IonButton,
  IonMenuButton,
  IonItemDivider,
  IonItem,
  IonLabel,
  IonInput
} from "@ionic/vue";
import { ellipse, square, menu } from "ionicons/icons";
import { mapState } from "vuex";
import { db } from "../../firebase";

export default {
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonImg,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    // IonMenuButton,
    // IonItemDivider,
  },
  setup() {
    return {
      ellipse,
      square,
      menu,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },
  data() {
    return {
      newProjectName: ''
    }
  },
  mounted() {
    window.localStorage.removeItem('vuex')
  },
  methods: {
    openSideMenu() {
      menuController.enable(true, "sidemenu");
      menuController.open("sidemenu");
    },
    async createProject() {
      const docRef = await addDoc(collection(db, `user/${this.user.uid}/project`), {
        name: this.newProjectName
      });
      window.location.reload()
    },
  },
};
</script>

<style lang="scss" scoped>
.clickable-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #DEDEDE;
  color: #6C6C6C;
  text-decoration: none;
}
</style>
