import apolloClient from "../services/apolloClient"
import ALL, { LEARN } from "@/constants/folders"
import { GET_BUNDLE, GET_BUNDLES_BY_TAG } from "@/graphql/bundle"


function _flat(arr:any=[], res = []) {
  return arr.reduce((acc:any, it:any)=>{
    if(!it.child) {
      return [...acc, it]
    }
    return [...acc, it, ..._flat(it.child)]
  }, res)
}

export default {
  state () {
    return {
      bundlesByTag: {},
      currentBundles: [],
      isBundlesLoading: false,
      bundleById: {},
      currentBundle: {
        questions: []
      },
      isBundleLoading: false,
      availableLanguages: [{
        name: 'Hindi',
        short: 'hi',
        id: '558d0fb7-729a-4bcb-87c1-3f2774487800'
      }, {
        name: 'English',
        short: 'en',
        id: '0f506717-ee80-44f1-b11b-0ddf15d8d559'
      }],
      loadingStatus: {}
    }
  },
  mutations: {
    SET_BUNDLES_BY_TAG(state:any, {key, bundles}:any) {
      state.bundlesByTag[key] = bundles
    },
    SET_CURRENT_BUNDLES(state:any, bundles:any) {
      state.currentBundles = bundles
    },
    SET_BUNDLES_LOADING_STATUS(state:any, status:boolean) {
      state.isBundlesLoading = status
    },
    SET_BUNDLE_BY_ID(state:any, {id, bundle}:any) {
      state.bundleById[id] = bundle
    },
    SET_CURRENT_BUNDLE(state:any, bundle:any) {
      state.currentBundle = bundle
    },
    SET_BUNDLE_LOADING_STATUS(state:any, status:boolean) {
      state.isBundleLoading = status
    },
    SET_LOADING_STATUS(state:any, {key, status}:any) {
      state.loadingStatus[key] = status
    }
  },
  actions: {
    async getItems({commit}:any, {ids=[]}:any) {
      return _flat(ALL).filter((it:any)=> ids.includes(it.id))
    },
    getItemDetail({commit}:any, {id}:any) {
      return _flat(ALL).find((it:any)=> it.id == id)
    },
    async getBundlesByTag({commit, state}:any, {tag_id, type=10, shouldUpdateLoadingStatus=true}:any) {
      const key = `${tag_id}_${type}`

      if(!state.bundlesByTag[key]) {
        commit('SET_LOADING_STATUS', {key, status: true})
      }
      if(shouldUpdateLoadingStatus && !state.bundlesByTag[key]) {
        commit('SET_BUNDLES_LOADING_STATUS', true)
      }
      commit('SET_CURRENT_BUNDLES', state.bundlesByTag[key] || [])

      try{
        const res1 = await apolloClient.query({
          query: GET_BUNDLES_BY_TAG({tag_id, type}),
        })
        console.log(res1.data)
        commit('SET_BUNDLES_BY_TAG', {key, bundles: res1.data.question_bundles})
        commit('SET_CURRENT_BUNDLES', res1.data.question_bundles)
        commit('SET_LOADING_STATUS', {key, status: false})
        if(shouldUpdateLoadingStatus) {
          commit('SET_BUNDLES_LOADING_STATUS', false)
        }
      }catch(e) {
        if(shouldUpdateLoadingStatus) {
          commit('SET_BUNDLES_LOADING_STATUS', false)
        }
        commit('SET_LOADING_STATUS', {key, status: false})
        return false
      }
    },
    async getBundle({commit, state}:any, {id, shouldUpdateLoadingStatus=true}:any) {
      if(shouldUpdateLoadingStatus && !state.bundleById[id]) {
        commit('SET_BUNDLE_LOADING_STATUS', true)
      }
      if(!state.bundleById[id]) {
        commit('SET_LOADING_STATUS', {key: id, status: true})
      }
      commit('SET_CURRENT_BUNDLE', state.bundleById[id] || {questions: []})
      if(state.bundleById[id] && state.bundleById[id].__fetched_at < Date.now() + 10*60*60*1000) {
        commit('SET_LOADING_STATUS', {key: id, status: false})
        // return true
      }
  
      try{
        const res1 = await apolloClient.query({
          query: GET_BUNDLE,
          variables: {
            id,
          }
        })
        res1.data.question_bundles_by_pk.__fetched_at = Date.now()
        commit('SET_BUNDLE_BY_ID', {id, bundle: res1.data.question_bundles_by_pk})
        commit('SET_CURRENT_BUNDLE', res1.data.question_bundles_by_pk)
        commit('SET_LOADING_STATUS', {key: id, status: false})
        if(shouldUpdateLoadingStatus) {
          commit('SET_BUNDLE_LOADING_STATUS', false)
        }
      }catch(e) {
        if(shouldUpdateLoadingStatus) {
          commit('SET_BUNDLE_LOADING_STATUS', false)
        }
        return false
      }
    },
  },
  getters: {
    bundles(state:any) {
      return state.currentBundles
    },
    isBundlesLoading(state:any) {
      return state.isBundlesLoading
    },
    bundle(state:any) {
      return state.currentBundle
    },
    quiz(state:any) {
      return state.currentBundle
    },
    isBundleLoading(state:any) {
      return state.isBundleLoading
    },
    availableLanguages(state:any) {
      return state.availableLanguages
    },
  },
}