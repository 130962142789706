<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :icon="close" default-href="/"></ion-back-button>
        </ion-buttons>
        <ion-title>{{quiz.title || 'Quiz'}}</ion-title>
        <ion-buttons slot="end">
          <ion-button transparent
            :disabled="!isLanguageSwitchable"
            @click="switchLanguage"
            fill="clear"
            size='small'>
            <img style="height: 100%; padding: 5px" src="/assets/language.png" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <nav-block-alert />
      <ion-spinner v-if="isBundleLoading" />
      <!-- <overlay-loader v-if="isBundleLoading" /> -->
      <question-item :question="question" />
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button
            style="font-size: 1.8em;"
            :disabled='!(current >1)'
            @click="$router.replace(`/quiz/running/${id}/?q=${current - 1}&s=${sessionId}`)">
            <ion-icon name='chevron-back' />
          </ion-button>
          <ion-button style="font-size: 1.2em; padding-left: 15px; padding-right: 15px">{{current}}/{{quiz.questions?.length}}</ion-button>
          <ion-button
            style="font-size: 1.8em;"
            :disabled='!(current < quiz.questions?.length)'
            @click="$router.replace(`/quiz/running/${id}/?q=${current + 1}&s=${sessionId}`)">
            <ion-icon name='chevron-forward' />
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button
            color='danger' fill='solid'
            @click="submit">Submit</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonLabel,
  IonAvatar,
  IonRippleEffect,
  IonBackButton,
  IonFooter,
  IonIcon,IonSpinner
} from "@ionic/vue";
import { close } from 'ionicons/icons';
import { mapGetters, mapActions, mapState} from 'vuex'
import NavBlockAlert from '../../components/NavBlockAlert.vue';
import { GET_BUNDLE } from '../../graphql/bundle';
import OverlayLoader from '../../components/OverlayLoader.vue';
import QuestionItem from './QuestionItem.vue';
import {createConfirmationAlert} from '../../composables/alert'

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    // IonLabel,
    // IonAvatar,
    // IonRippleEffect,
    IonBackButton,
    IonFooter,
    IonIcon,
    NavBlockAlert,
    IonSpinner,
    // OverlayLoader,
    QuestionItem,
  },
  setup() {
    return {
      close, 
      createConfirmationAlert
    };
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['quiz', 'isBundleLoading']),
    ...mapState({
      answers: state=> state.quiz.answers,
			availableLanguages: state => state.quiz.availableLanguages,
			language_id: state => state.quiz.language_id,
			sessionId: state => state.quiz.session_id
    }),
    id() {
      return this.$route.params.quizId
    },
    question() {
      let q = this.quiz.questions?.[this.current - 1] || {translations: []}
      let tr = q.translations.find(tr=> tr.language_id == this.language_id)
      if(!tr) {
        tr = q.translations[0]
      }
      if(!tr) {
        return q
      }
      let {question, explanation, a, b, c, d} = tr
      Object.assign(q, {question, explanation, a, b, c, d})
      return q
    },
    current() {
      return parseInt(this.$route.query.q || '1')
    },
    isLanguageSwitchable() {
      if(!this.current){
        return {translations: []}
      }
      let q = this.quiz.questions[this.current -1] || {translations: []}
      return q.translations.length > 1
    }
  },
  watch: {
    sessionId: {
      handler() {
        if(this.sessionId && this.sessionId !== this.$route.query.s) {
          this.$router.replace(`/quiz/running/${this.id}/?q=${this.current}&s=${this.sessionId}`)
        }
      },
      immediate: true
    },
    quiz: {
      handler() {
        this.setTotalQuestions({total: this.quiz.questions.length})
      },
      immediate: true
    }
  },
  mounted() {
    this.getBundle({id: this.id})
    this.setId({id: this.id})
  },
  methods: {
    ...mapActions(['toggleNavBlock', 'getBundle']),
    ...mapActions({
      setId: 'quiz/setId',
      finishQuiz: 'quiz/finishQuiz',
      setTotalQuestions: 'quiz/setTotalQuestions',
      changeLanguage: 'quiz/changeLanguage',
    }),
    switchLanguage() {
      if(this.language_id == '558d0fb7-729a-4bcb-87c1-3f2774487800') {
        this.changeLanguage({language_id: '0f506717-ee80-44f1-b11b-0ddf15d8d559'})
      }else{
        this.changeLanguage({language_id: '558d0fb7-729a-4bcb-87c1-3f2774487800'})
      }
    },
    async submit() {
      if(await this.createConfirmationAlert('Confirm submition?')) {
        await this.finishQuiz()
        await this.toggleNavBlock({isBlocked: false})
        console.log(65)
        const sessionId = this.sessionId
        this.$router.replace(`/quiz/analysis/${this.id}/${sessionId}`)
      }
    }
  }
}
</script>
