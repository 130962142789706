import gql from "graphql-tag";


export const CREATE_ERROR_REPORT = gql`
  mutation($object: error_reports_insert_input = {}) {
    insert_error_reports_one(object: $object) {
      id
    }
  }
`

export const GET_VERSION = gql`
query {
  version_control {
    version
    is_required
    is_active
  }
}
`
export const GET_TAGS = gql`
query {
  tags {
    id
    name
    type
  }
}
`
