
const LEARN = [
  {
    name: 'GK',
    id: 'learn-gk',
    child: [
      {
        name: 'History',
        id: 'history',
        child: [
          {
            name: 'Ancient History',
            id: '5b87b37a-fc01-413f-8d67-085f29e1355a',
            slug: 'resources',
            resources: ['flashcard', 'quiz'],
            childCount: -1
          },
          {
            name: 'Medieval History',
            id: '9931b4c1-ea2d-4452-8afd-a7f6f5703333',
            slug: 'resources',
            resources: ['flashcard', 'quiz'],
            childCount: -1
          },
          {
            name: 'Modern History',
            id: 'ae14328c-534f-4ac8-976a-61d2e199d276',
            slug: 'resources',
            resources: ['flashcard', 'quiz'],
            childCount: -1
          },
          {
            name: 'World History',
            id: '9a445a04-aed9-402d-aa9b-8ffe477e2753',
            slug: 'resources',
            resources: ['flashcard', 'quiz'],
            childCount: -1
          },
        ]
      },
      {
        name: 'Geography',
        id: 'geography',
        child: [
          {
            name: 'Physical Geography',
            id: '6e552d47-6771-41f4-940d-f0f0b9799dd9',
            slug: 'resources',
            resources: ['flashcard', 'quiz'],
            childCount: -1
          },
          {
            name: 'Indian Geography',
            id: 'bfb9fcc9-70ff-4d5f-a3c9-4c0ac06ab143',
            slug: 'resources',
            resources: ['flashcard', 'quiz'],
            childCount: -1
          },
          {
            name: 'World Geography',
            id: 'c3cedcbd-e8ce-4ee7-95b6-039a81950208',
            slug: 'resources',
            resources: ['flashcard', 'quiz'],
            childCount: -1
          }
        ]
      },
      {
        name: 'Science',
        id: 'science',
        child: [
          {
            name: 'Physics',
            id: '8285e5ec-9b96-4312-9d36-e6a7a964d610',
            slug: 'resources',
            resources: ['flashcard', 'quiz'],
            childCount: -1
          },
          {
            name: 'Chemistry',
            id: '1e2cbbc4-d691-40d4-a8c2-c0c006ddfa12',
            slug: 'resources',
            resources: ['flashcard', 'quiz'],
            childCount: -1
          },
          {
            name: 'Biology',
            id: '67da2105-e6b7-4a63-885b-a153856ca1d6',
            slug: 'resources',
            resources: ['flashcard', 'quiz'],
            childCount: -1
          }
        ]
      },
      {
        name: 'Economy',
        id: 'economy',
        child: [
          {
            name: 'Micro/macro Economy',
            id: 'be452f8c-e2de-4a01-9410-f584dd9ceb98',
            slug: 'resources',
            resources: ['flashcard', 'quiz'],
            childCount: -1
          },
          {
            name: 'Indian Economy',
            id: '4d7e95a2-d2cf-4eb7-9915-343c4b86e126',
            slug: 'resources',
            resources: ['flashcard', 'quiz'],
            childCount: -1
          }
        ]
      },
      {
        name: 'Polity',
        id: 'b617b94f-5b01-48f4-87a5-054f7aceb190',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: -1
        // child: [
        //   {
        //     name: 'Constitution of India',
        //     id: 'constitution-of-india',
        //     slug: 'resources',
        //     resources: ['flashcard', 'quiz'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'Amendments in Constitution',
        //     id: 'amendments-in-constitution',
        //     slug: 'resources',
        //     resources: ['flashcard', 'quiz'],
        //     childCount: 6
        //   }
        // ]
      },
      {
        name: 'Miscellaneous GK',
        id: '5b5942c6-38e7-4dc6-affc-ad1b1844fb62',
        slug: 'resources',
        childCount: 6,
        // child: [
        //   {
        //     name: 'Awards and Samman',
        //     id: 'gk-awards',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'Schemes(States)',
        //     id: 'gk-schemes',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'First in India (Male)',
        //     id: 'gk-first-in-india-male',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'First in India (Feale)',
        //     id: 'gk-first-in-india-female',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'First in World',
        //     id: 'gk-first-in-world',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'Biggest and Smallest in India',
        //     id: 'gk-biggest-india',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'Biggest and Smallest in World',
        //     id: 'gk-biggest-world',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'News Agency of Country',
        //     id: 'gk-bews-agency',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'Parliament of Countries',
        //     id: 'gk-parliament',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'Books and Authors',
        //     id: 'gk-book-n-authors',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'Important days',
        //     id: 'gk-important',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'Training Institute in India',
        //     id: 'gk-training',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'Research Centers in India',
        //     id: 'gk-research-india',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'Security Organisation',
        //     id: 'gk-security-organisation',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'PSU/PUs and its Establishments',
        //     id: 'gk-psu-pu',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'Musical Instruments and its Exponents',
        //     id: 'gk-musical',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'National Parks',
        //     id: 'gk-national-parks',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'Cultural organisation and its Establishment',
        //     id: 'gk-cultural-organisation',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   },
        //   {
        //     name: 'Eminent Persons',
        //     id: 'gk-eminent-person',
        //     slug: 'resources',
        //     resources: ['flashcard'],
        //     childCount: 6
        //   }
        // ]
      }
    ]
  },
  {
    name: 'English',
    id: 'learn-english',
    child: [
      {
        name: "Vocabulary/word meaning",
        id: 'english-vocab',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "One word substitution",
        id: '49ef00c1-b9d3-4fa4-893e-aa77a291cabe',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Idoms and Phrases",
        id: '920fab43-36a6-4ece-be51-77dfdeb0ba96',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
    ]
  },
  {
    name: 'Math',
    id: 'learn-math',
    child: [
      {
        name: "Simple Interest",
        id: 'math-simple-interest',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Linear equations",
        id: 'math-linear-equation',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Permutations and combinations",
        id: 'math-perm-n-comb',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Mixture and allegation",
        id: 'math-mixture',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Trigonometry",
        id: 'math-trigonometry',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Simplification",
        id: 'math-simplification',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Pipes and cisterns",
        id: 'math-pipes',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Area and perimeters",
        id: 'math-area-n-perimeter',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Height and distance",
        id: 'math-height-n-distance',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Square and cube roots",
        id: 'math-roots',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Geometry",
        id: 'math-geometry',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Number System",
        id: 'math-number-system',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Profit and loss",
        id: 'math-profit-n-loss',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Boat and stream",
        id: 'math-boat-stream',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Speed, time and distance",
        id: 'math-speed-time',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Work and time",
        id: 'math-work-time',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Work and wages",
        id: 'math-work-wages',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Fractions",
        id: 'math-fractions',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Compound Interest",
        id: 'math-compound-interest',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Discount",
        id: 'math-discount',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Ratio and proportions",
        id: 'math-ratio-n-prop',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "LCM and HCF",
        id: 'math-lcm-hcf',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Series and progression",
        id: 'math-series',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Partnership",
        id: 'math-partnership',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Age based question tricks",
        id: 'math-age-tricks',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Indices and surds",
        id: 'math-indices-n-surds',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Percentage",
        id: 'math-percentage',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
      {
        name: "Quadratic equations",
        id: 'math-quadratic',
        slug: 'resources',
        resources: ['flashcard', 'quiz'],
        childCount: 6
      },
    ]
  },
  {
    name: 'SSC GK(PYQ)',
    id: 'ssc-gk-pyq',
    hasNewItem: false,
    child: [
      {
        name: 'SSC CHSL',
        id: 'ssc-chsl',
        hasNewItem: false,
        child: [
          {
            name: 'SSC CHSL (2020)',
            id: 'fb1f12b3-08ef-465a-b173-b8333f46f27e',
            hasNewItem: false,
            slug: 'resources',
            resources: ['quiz'],
            childCount: -1
          },
          {
            name: 'SSC CHSL (2019)',
            id: '0dc8a5d0-47bc-448e-a5b1-3c9e531be90b',
            hasNewItem: false,
            slug: 'resources',
            resources: ['quiz'],
            childCount: -1
          },
        ]
      },
      {
        name: 'SSC CGL',
        id: 'ssc-cgl',
        hasNewItem: false,
        child: [
          {
            name: 'SSC CGL (2016)',
            id: '0b37798c-4074-4dc6-ae0d-173b05a551d8',
            hasNewItem: false,
            slug: 'resources',
            resources: ['quiz'],
            childCount: -1
          },
          {
            name: 'SSC CGL (2017)',
            id: 'f0bf98d8-e5fb-41f9-a866-8efba8d68d67',
            hasNewItem: false,
            slug: 'resources',
            resources: ['quiz'],
            childCount: -1
          },
          {
            name: 'SSC CGL (2018)',
            id: '08613c5c-a7cd-4b89-b474-43a58e80e91a',
            hasNewItem: false,
            slug: 'resources',
            resources: ['quiz'],
            childCount: -1
          },
          {
            name: 'SSC CGL (2019)',
            id: 'abf50a7e-173a-4196-8adc-ca0366b23ac0',
            hasNewItem: false,
            slug: 'resources',
            resources: ['quiz'],
            childCount: -1
          },
          {
            name: 'SSC CGL (2020)',
            id: 'db6280a9-7ab2-4b09-b89d-bcf0989e3785',
            hasNewItem: false,
            slug: 'resources',
            resources: ['quiz'],
            childCount: -1
          },
        ]
      },
      {
        name: 'SSC CPO',
        id: 'ssc-cpo',
        hasNewItem: false,
        child: [
          {
            name: 'SSC CPO (2019)',
            id: '0fe958a0-da17-4ec1-848d-2391f164059c',
            hasNewItem: false,
            slug: 'resources',
            resources: ['quiz'],
            childCount: -1
          }
        ]
      },
      {
        name: 'SSC GD',
        id: 'ssc-gd',
        hasNewItem: false,
        child: [
          {
            name: 'SSC GD (2019)',
            id: '48dc143b-df8e-45d6-932f-43b911f20dcd',
            hasNewItem: false,
            slug: 'resources',
            resources: ['quiz'],
            childCount: -1
          }
        ]
      },
      {
        name: 'SSC MTS',
        id: 'ssc-mts',
        hasNewItem: false,
        child: [
          {
            name: 'SSC MTS (2019)',
            id: 'f01d3efc-08e7-4a6f-9dac-2510acaba273',
            hasNewItem: false,
            slug: 'resources',
            resources: ['quiz'],
            childCount: -1
          }
        ]
      },
    ]
  },
]

const PRACTICE = [
  {
    name: 'Full Test',
    id: 'full-test',
    hasNewItem: false,
    child: [
      {
        name: 'SSC',
        id: 'ssc2',
        hasNewItem: false,
        child: [
          {
            name: 'SSC CGL',
            id: 'ssc-cgl2',
            child: [
              {
                name: 'PYQ',
                id: 'ssc-cgl2-pyq',
                child: [
                  {
                    name: 'SSC CGL (2019)',
                    id: 'a3874219-e283-4272-b431-bb529d8b1a5d',
                    hasNewItem: false,
                    slug: 'resources',
                    resources: ['exam'],
                    childCount: -1
                  }
                ]
              },
              {
                name: 'Mock Test',
                id: '98bb0409-cf03-4028-a2d6-335630796cad',
                hasNewItem: false,
                comingSoon: true,
                child: []
              },
            ]
          }
        ]
      },
    ]
  },
  {
    name: 'Sectional Test',
    id: '31fd3562-45ef-4f79-9b53-45351ced9f9f',
    slug: 'resources',
    resources: ['exam'],
    childCount: 1,
    // hasNewItem: false,
    // child: [
    //   // {
    //   //   name: 'Sectional Test A',
    //   //   id: '31fd3562-45ef-4f79-9b53-45351ced9f9f',
    //   //   slug: 'resources',
    //   //   resources: ['exam'],
    //   //   childCount: -1
    //   // },
    //   // {
    //   //   name: 'General Awareness',
    //   //   id: 'gk1',
    //   //   slug: 'resources',
    //   //   resources: ['exam'],
    //   //   childCount: -1
    //   // },
    //   // {
    //   //   name: 'Quantitative Aptitude',
    //   //   id: 'math1',
    //   //   slug: 'resources',
    //   //   resources: ['exam'],
    //   //   childCount: -1
    //   // },
    //   // {
    //   //   name: 'General English and Comprehension',
    //   //   id: 'f77f2e56-b56f-42f2-a15c-e7b518a91e74',
    //   //   hasNewItem: false,
    //   //   slug: 'resources',
    //   //   resources: ['exam'],
    //   //   childCount: -1
    //   // },
    // ]
  }
]

const REVISE = [
  {
    name: 'Formula',
    id: 'ee04d62b-fa06-4562-9e07-e4b2ee2704e8sf',
    hasNewItem: false,
    child: [
      {
        name: 'Trigonometry',
        id: 't1'
      },
      {
        name: 'Motion',
        id: 'l2'
      },
      {
        name: 'Vector',
        id: 'v3'
      }
    ]
  },
  {
    name: 'Common Words',
    id: 'ee04d62b-fa06-4562-9e07-e4b2ee2704e8sc',
    hasNewItem: false,
    comingSoon: true
  },
]

const SUBJECT_IDS = LEARN.map(it=> it.id)
const PRACTICE_HOME_IDS = PRACTICE.map(it=> it.id)

export default [
  ...LEARN,
  ...PRACTICE,
  ...REVISE
]
export {
  LEARN,
  PRACTICE,
  SUBJECT_IDS,
  PRACTICE_HOME_IDS,
  REVISE
}
