

export default {
  namespaced: true,
  state () {
    return {
      id: "",
      session_id: "",
      startedAt: 0,
      visited: [],
      bookmarks: [],
      answers: {},
			textSize: 3,
      totalQuestions: 0,
      isRightSliderOpen: false,
      sessionExpired: false,
      availableLanguages: [{
        name: 'Hindi',
        short: 'hi',
        id: '558d0fb7-729a-4bcb-87c1-3f2774487800'
      }, {
        name: 'English',
        short: 'en',
        id: '0f506717-ee80-44f1-b11b-0ddf15d8d559'
      }],
      language_id: '0f506717-ee80-44f1-b11b-0ddf15d8d559',
      sections: [
        {
          name: 'General English and Comprehension',
          id: 'f77f2e56-b56f-42f2-a15c-e7b518a91e74',
        },
        {
          name: 'Quantitative Aptitude',
          id: 'd70691d9-ef5b-4739-a3ae-8607c639a9a7',
        },
        {
          name: 'General Intelligence and Reasoning',
          id: 'dc653776-6693-4c23-b85c-d70c8e04ab5a',
        },
        {
          name: 'General Awareness',
          id: '346f2e58-4321-41c0-828f-3a1368cecb32',
        }
      ],
      currentSection: '68db504a-3fad-464a-895c-5fc9c2b53d45',
      sessions: [],
      actions: []
    }
  },
  mutations: {
    SET_ID(state:any, {id}:any) {
      if(state.id !== id) {
        if(state.id && state.session_id) {
          // Backup session data
          state.actions = [...state.actions, {
            type: 'SUBMIT',
            created_at: Date.now()
          }]
          state.sessions = state.sessions.filter((s:any)=> !(s.session_id == state.session_id && s.id == state.id)) 
          state.sessions = [
            ...state.sessions,
            {
              id: state.id,
              startedAt: state.startedAt,
              totalQuestions: state.totalQuestions,
              session_id: state.session_id,
              visited: state.visited,
              bookmarks: state.bookmarks,
              answers: state.answers,
              actions: state.actions,
              sessionExpired: true
            }
          ]
        }
        state.visited = []
        state.bookmarks = []
        state.answers = {}
        state.session_id = ""
        state.sessionExpired = false
        if(id) {
          // Create a new session
          state.startedAt = Date.now()
          state.session_id = (state.sessions.filter((s:any)=> s.id == id).length + 1).toString()
          state.actions = [{
            type: 'CREATE_SESSION',
            created_at: Date.now(),
            session_id: state.session_id
          }]
        }
      }else if (state.sessionExpired) {
        console.log('creating new')
        state.visited = []
        state.bookmarks = []
        state.answers = {}
        state.sessionExpired = false
        // Create a new session
        state.startedAt = Date.now()
        state.session_id = (state.sessions.filter((s:any)=> s.id == id).length + 1).toString()
        state.actions = [{
          type: 'CREATE_SESSION',
          created_at: Date.now(),
          session_id: state.session_id
        }]
      }
      state.isRightSliderOpen = false
      state.id = id
    },
    VISIT(state:any, id:string) {
      state.actions = [...state.actions, {
        type: 'VISIT',
        payload: id,
        created_at: Date.now()
      }]
      if(!state.visited.includes(id)) {
        state.visited = [id, ...state.visited]
      }
    },
    ADD_BOOKMARK(state:any, id:string) {
      if(!state.bookmarks.includes(id)) {
        state.bookmarks = [id, ...state.bookmarks]
      }
    },
    REMOVE_BOOKMARK(state:any, id:string) {
      if(state.bookmarks.includes(id)) {
        state.bookmarks = state.bookmarks.filter((b:string)=> b!== id)
      }
    },
    CLEAR_ANSWER(state:any, id:string) {
      state.actions = [...state.actions, {
        type: 'CLEAR_ANSWER',
        payload: {id},
        created_at: Date.now()
      }]
      state.answers[id] = null
      delete state.answers[id]
    },
    SET_ANSWER(state:any, {id, answer}:any) {
      state.actions = [...state.actions, {
        type: 'SET_ANSWER',
        payload: {id, answer},
        created_at: Date.now()
      }]
      state.answers[id] = answer
    },
    SET_TOTAL_QUESTIONS(state:any, {total}:any) {
      state.totalQuestions = total
    },
    SET_TEXT_SIZE(state:any, {textSize=3}:any) {
      state.textSize = textSize
    },
    TOGGLE_RIGHT_SLIDER(state:any, open:boolean) {
      state.isRightSliderOpen = open
    },
    CHANGE_LANGUAGE(state:any, language_id:string) {
      state.language_id = language_id
    },
    SET_SECTION(state:any, section_id:string) {
      state.currentSection = section_id
    },
    RESTORE_SESSION(state:any, {id, sessionId}:any) {
      const session = state.sessions.find((s:any)=> (s.session_id == sessionId && s.id == id))
      if(session) {
        state.id = session.id,
        state.session_id = session.session_id
        state.visited = session.visited
        state.bookmarks = session.bookmarks
        state.answers = session.answers
        state.actions = session.actions
        state.startedAt = session.startedAt
        state.totalQuestions = session.totalQuestions
        state.sessionExpired = session.sessionExpired
      }
    },
    SUBMIT_SESSION(state:any) {
      if(state.id && state.session_id) {
        // Backup session data
        state.sessionExpired = true
        state.actions = [...state.actions, {
          type: 'SUBMIT',
          created_at: Date.now()
        }]
        state.sessions = state.sessions.filter((s:any)=> !(s.session_id == state.session_id && s.id == state.id)) 
        state.sessions = [
          ...state.sessions,
          {
            id: state.id,
            startedAt: state.startedAt,
            totalQuestions: state.totalQuestions,
            session_id: state.session_id,
            visited: state.visited,
            bookmarks: state.bookmarks,
            answers: state.answers,
            actions: state.actions,
            sessionExpired: true
          }
        ]
      }
    }
  },
  actions: {
    async setId({ commit, state }:any, {id, sessionId}:any) {
      await commit('SET_ID', {id, sessionId})
      return state.session_id
    },
    visit({commit}:any, {id}:any) {
      commit('VISIT', id)
    },
    addBookmark({commit}:any, {id}:any) {
      commit('ADD_BOOKMARK', id)
    },
    removeBookmark({commit}:any, {id}:any) {
      commit('REMOVE_BOOKMARK', id)
    },
    clearAnswer({commit}:any, {id}:any) {
      commit('CLEAR_ANSWER', id)
    },
    setAnswer({commit}:any, {id, answer}:any) {
      commit('SET_ANSWER', {id, answer})
    },
    setTotalQuestions({commit}:any, {total}:any) {
      commit('SET_TOTAL_QUESTIONS', {total})
    },
    toggleRightSlider({commit}:any, open=false) {
      commit('TOGGLE_RIGHT_SLIDER', open)
    },
    changeLanguage({commit}:any, {language_id}:any) {
      commit('CHANGE_LANGUAGE', language_id)
    },
    setSection({commit}:any, {section_id}:any) {
      commit('SET_SECTION', section_id)
    },
    setTextSize({commit}:any, {textSize}:any) {
      commit('SET_TEXT_SIZE', {textSize})
    },
    finishExam({commit}:any) {
      commit('SUBMIT_SESSION', '')
    },
    restoreSession({commit}:any, {id, sessionId}:any) {
      commit('RESTORE_SESSION', {id, sessionId})
    },
    submitSession({commit}:any) {
      commit('SUBMIT_SESSION', '')
    }
  },
  getters: {
    attempted(state:any) {
      return Object.keys(state.answers).filter((id:string)=> state.answers[id])
    },
    markedAndAnswered(state:any) {
      return state.bookmarks.filter((id:string)=> state.answers[id])
    }
  },
}