

export default {
  namespaced: true,
  state () {
    return {
      id: "",
      session_id: "",
      startedAt: 0,
      answers: {},
      totalQuestions: 0,
      availableLanguages: [{
        name: 'Hindi',
        short: 'hi',
        id: '558d0fb7-729a-4bcb-87c1-3f2774487800'
      }, {
        name: 'English',
        short: 'en',
        id: '0f506717-ee80-44f1-b11b-0ddf15d8d559'
      }],
      language_id: '0f506717-ee80-44f1-b11b-0ddf15d8d559',
      sessions: [],
      actions: []
    }
  },
  mutations: {
    SET_ID(state:any, id:string) {
      if(state.id !== id) {
        if(state.id && state.session_id) {
          // Backup session data
          state.actions = [...state.actions, {
            type: 'SUBMIT',
            created_at: Date.now()
          }]
          state.sessions = state.sessions.filter((s:any)=> !(s.session_id == state.session_id && s.id == state.id)) 
          state.sessions = [
            ...state.sessions,
            {
              id: state.id,
              startedAt: state.startedAt,
              totalQuestions: state.totalQuestions,
              session_id: state.session_id,
              answers: state.answers,
              actions: state.actions,
              submitted: true
            }
          ]
        }
        state.answers = {}
        if(id) {
          // Create a new session
          state.startedAt = Date.now()
          state.session_id = (state.sessions.filter((s:any)=> s.id == id).length + 1).toString()
          state.submitted = false
          state.actions = [{
            type: 'CREATE_SESSION',
            created_at: Date.now(),
            session_id: state.session_id
          }]
        }
      }else if(state.submitted){
        state.answers = {}
        state.startedAt = Date.now()
        state.session_id = (state.sessions.filter((s:any)=> s.id == id).length + 1).toString()
        state.submitted = false
        state.actions = [{
          type: 'CREATE_SESSION',
          created_at: Date.now(),
          session_id: state.session_id
        }]
      }
      // console.log(state.sessions, state.session_id)
      state.id = id
    },
    VISIT(state:any, id:string) {
      state.actions = [...state.actions, {
        type: 'VISIT',
        payload: id,
        created_at: Date.now()
      }]
    },
    CLEAR_ANSWER(state:any, id:string) {
      state.actions = [...state.actions, {
        type: 'CLEAR_ANSWER',
        payload: {id},
        created_at: Date.now()
      }]
      state.answers[id] = null
      delete state.answers[id]
    },
    SET_ANSWER(state:any, {id, answer}:any) {
      state.actions = [...state.actions, {
        type: 'SET_ANSWER',
        payload: {id, answer},
        created_at: Date.now()
      }]
      state.answers[id] = answer
    },
    SET_TOTAL_QUESTIONS(state:any, {total}:any) {
      state.totalQuestions = total
    },
    CHANGE_LANGUAGE(state:any, language_id:string) {
      state.language_id = language_id
    },
    RESTORE_SESSION(state:any, {id, sessionId}:any) {
      const session = state.sessions.find((s:any)=> (s.session_id == sessionId && s.id == id))
      if(session) {
        state.id = session.id
        state.session_id = session.session_id
        state.answers = session.answers
        state.actions = session.actions
        state.startedAt = session.startedAt
        state.totalQuestions = session.totalQuestions
        state.submitted = session.submitted
      }
    }
  },
  actions: {
    setId({ commit }:any, {id}:any) {
      commit('SET_ID', id)
    },
    visit({commit}:any, {id}:any) {
      commit('VISIT', id)
    },
    clearAnswer({commit}:any, {id}:any) {
      commit('CLEAR_ANSWER', id)
    },
    setAnswer({commit}:any, {id, answer}:any) {
      commit('SET_ANSWER', {id, answer})
    },
    setTotalQuestions({commit}:any, {total}:any) {
      commit('SET_TOTAL_QUESTIONS', {total})
    },
    changeLanguage({commit}:any, {language_id}:any) {
      commit('CHANGE_LANGUAGE', language_id)
    },
    finishQuiz({commit}:any) {
      commit('SET_ID', '')
    },
    restoreSession({commit}:any, {id, sessionId}:any) {
      commit('RESTORE_SESSION', {id, sessionId})
    }
  },
  getters: {
    attempted(state:any) {
      return Object.keys(state.answers)
    }
  },
}