import gql from "graphql-tag";

export const GET_BUNDLE = gql`
  query ($id: uuid!) {
    question_bundles_by_pk(id: $id) {
      id
      title
      type
      description
      permission
      publish_at
      time_limit
      instruction_id
      languages
      _type {
        name
        value
      }
      _permission {
        name
        value
      }
      tags {
        id
        name
      }
      questions(order_by: {index: asc}) {
        id
        index
        comprehension_id
        tags {
          id
          name
        }
        translations {
          question
          a
          b
          c
          d
          e
          language_id
          explanation
        }
        answer
        comprehension {
          id
          translations {
            question
            language_id
          }
        }
      }
    }
  }
`

export const GET_BUNDLES_BY_TAG = (cond={} as any) => {
  const condition = []
  if(cond.type) {
    condition.push(`type: {_eq: ${cond.type}}`)
  }
  if(cond.tag_id) {
    condition.push(`tags: {id: {_eq: "${cond.tag_id}"}}`)
  }
  const condition_str = `{${condition.join(',')}}`
  return gql`
    query {
      question_bundles(where: ${condition_str}, order_by: {publish_at: desc}) {
        id
        title
        type
        languages
        time_limit
        publish_at
        instruction_id
        questions_aggregate {
          aggregate {
            count(columns: id)
          }
        }
      }
    }
  `
}

