
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  isPlatform
} from "@ionic/vue";
import {
  ellipse,
  square,
  triangle,
  homeSharp,
  trendingUpOutline,
  barbell,
  albums,
  analyticsSharp
} from "ionicons/icons";

export default {
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    return {
      ellipse,
      square,
      triangle,
      homeSharp,
      trendingUpOutline,
      barbell,
      albums,
      analyticsSharp,
      isDesktop: isPlatform("desktop") && !isPlatform("android"),
    };
  },
};
