<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>Practice</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      
      <folder-container :items="subjects" />
      <ion-item-divider style="min-height: 50px;" />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonMenuButton,
  IonItemDivider,
} from "@ionic/vue";
import { pin, walk, warning, wifi, wine, ellipsisHorizontalSharp, arrowForward } from 'ionicons/icons';
import RecentlyCard from '../components/RecentlyCard.vue';
import {mapActions} from 'vuex'
import FolderContainer from '../components/FolderContainer.vue';
import { PRACTICE_HOME_IDS} from '../constants/folders'

export default {
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButtons,
    IonMenuButton,
    IonItemDivider,
    FolderContainer 
  },
  setup() {
    return {
      pin, walk, warning, wifi, wine, ellipsisHorizontalSharp, arrowForward
    };
  },
  data() {
    return {
      subjects: []
    }
  },
  computed: {
  },
  methods: {
    ...mapActions(['getItems']),
  },
  async mounted() {
    let subjects = await this.getItems({ids: PRACTICE_HOME_IDS})
    console.log(subjects)
    subjects = subjects.map(it=>({
      ...it,
      href: `/${it.id}/${it.slug || 'topics'}`,
      subtitle: it.comingSoon?'Coming soon':
                `${it.childCount || it.child?.length} items`
    }))
    this.subjects = subjects
  }
};
</script>

<style lang="scss" scoped>
</style>