import apolloClient from "../services/apolloClient"
import { GET_SUBSCRIPTIONS } from "@/graphql/subscription"

export default {
  namespaced: true,
  state () {
    return {
      subscriptions: [],
      isLoadingSubscriptions: false
    }
  },
  mutations: {
    SET_SUBSCRIPTIONS(state:any, subscriptions:any) {
      state.subscriptions = subscriptions
    },
    SET_SUBSCRIPTIONS_IS_LOADING(state:any, status:boolean) {
      state.isLoadingSubscriptions = status
    },
  },
  actions: {
    async getSubscriptions({ commit, state }:any) {
      if(state.subscriptions?.length == 0) {
        commit('SET_SUBSCRIPTIONS_IS_LOADING', true)
      }
      const res = await apolloClient.query({
        query: GET_SUBSCRIPTIONS,
      })
      commit('SET_SUBSCRIPTIONS_IS_LOADING', false)
      commit('SET_SUBSCRIPTIONS', res.data.subscription)
    },
  },
  getters: {
  },
}