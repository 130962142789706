import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { useStore } from 'vuex';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import HomeTabHolder from '../views/HomeTabHolder.vue'
import store from './../store'

import { getCurrentUser} from '../firebase'

import HomePage from '@/views/HomePage.vue'
import PracticePage from '@/views/PracticePage.vue'
import LearnPage from '@/views/LearnPage.vue'
import RevisePage from '@/views/RevisePage.vue'
import PerformancePage from '@/views/PerformancePage.vue'
import TopicPage from '@/views/TopicPage.vue'
import ResourcePage from '@/views/ResourcePage/ResourcePage.vue'
import QuizInstructionPage from '@/views/QuizPage/QuizInstructionPage.vue'
import QuizPage from '@/views/QuizPage/QuizPage.vue'
import QuizAnalysisPage from '@/views/QuizPage/QuizAnalysisPage.vue'
import FlashcardInstructionPage from '@/views/FlashcardPage/FlashcardInstructionPage.vue'
import FlashcardPage from '@/views/FlashcardPage/FlashcardPage.vue'
import ExamInstructionPage from '@/views/ExamPage/ExamInstructionPage.vue'
import ExamDetailPage from '@/views/ExamPage/ExamDetailPage.vue'
import ExamPage from '@/views/ExamPage/ExamPage.vue'
import ExamAnalysisPage from '@/views/ExamPage/ExamAnalysisPage.vue'
import ExamLeaderboardPage from '@/views/Leaderboards/ExamLeaderboardPage.vue'

// Teacher
import DashboardTabHolder from '@/views/FirebaseFunctions/DashboardTabHolder.vue'
import DashboardPage from '@/views/FirebaseFunctions/DashboardPage.vue'
import TeacherAccountPage from '@/views/FirebaseFunctions/AccountPage.vue'
import TeacherQuestionFileCreatePage from '@/views/FirebaseFunctions/Function/CreatePage.vue'
import TeacherFilesPage from '@/views/FirebaseFunctions/Function/ListPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/app-maker/dashboard'
  },
  {
    path: '/',
    component: HomeTabHolder,
    name: "main",
    children: [
      {
        path: '/home',
        component: HomePage
      },
      {
        path: '/practice',
        component: PracticePage,
        name: '/practice'
      },
      {
        path: 'learn',
        component: LearnPage,
        name: 'learn',
      },
      {
        path: 'revise',
        component: RevisePage,
        name: 'revise'
      },
      {
        path: 'performance',
        component: PerformancePage,
        name: 'performance'
      },
      {
        path: ':subjectId/topics',
        component: TopicPage
      },
      {
        path: '/:topicId/resources',
        component: ResourcePage
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Auth/LoginGmailPage.vue'),
    meta: {
      shouldAuth: false,
      redirect: '/app-maker'
    }
  },
  {
    path: '/app-maker',
    name: 'app-maker',
    component: DashboardTabHolder,
    children: [
      {
        path: '/app-maker',
        redirect: '/app-maker/dashboard'
      },
      {
        path: 'dashboard',
        component: DashboardPage
      },
      {
        path: 'files',
        component: TeacherFilesPage
      },
      {
        path: '/app-maker/setting',
        component: () => import('@/views/FirebaseFunctions/SettingPage.vue'),
      },
      {
        path: '/app-maker/account',
        component: TeacherAccountPage,
      },
      {
        path: '/app-maker/function-file/create',
        name: 'app-maker-function-file-create',
        component: TeacherQuestionFileCreatePage
      },
      {
        path: '/app-maker/function-file/:id/edit',
        name: 'app-maker-function-file-edit',
        component: TeacherQuestionFileCreatePage
      },
    ]
  },
  {
    path: '/subscriptions/',
    name: 'subscriptions',
    component: ()=> import('@/views/Subscriptions/IndexPage.vue'),
    children: [
      {
        path: '',
        component: ()=> import('@/views/Subscriptions/SubscriptionList.vue'),
      },
    ]
  },
  {
    path: '/quiz/start/:quizId',
    component: QuizInstructionPage,
    name: 'instruction',
  },
  {
    path: '/quiz/running/:quizId/',
    component: QuizPage,
    name: 'quiz',
    meta: {
      preventBackNav: true
    },
  },
  {
    path: '/quiz/analysis/:quizId/:sessionId?',
    component: QuizAnalysisPage,
    name: 'analysis',
  },
  {
    path: '/flashcard/start/:quizId',
    component: FlashcardInstructionPage,
    name: 'flashcard_instruction',
  },
  {
    path: '/flashcard/running/:quizId/',
    component: FlashcardPage,
    name: 'flashcard',
    meta: {
      preventBackNav: true
    }
  },
  {
    path: '/exam/start/:examId',
    component: ExamInstructionPage,
    name: 'exam_instruction',
  },
  {
    path: '/exam/detail/:examId',
    component: ExamDetailPage,
    name: 'exam_detail',
  },
  {
    path: '/exam/running/:quizId/',
    component: ExamPage,
    name: 'exam',
    meta: {
      preventBackNav: true
    }
  },
  {
    path: '/exam/analysis/:quizId/:sessionId?',
    component: ExamAnalysisPage,
    name: 'exam_analysis',
  },
  {
    path: '/exam/leaderboard/:quizId',
    component: ExamLeaderboardPage,
    name: 'exam_leaderboard',
  },
  {
    path: '/test',
    component: import('@/views/TestPage.vue')
  },
  // {
  //   path:  '/:pathMatch(.*)',
  //   redirect: '/home'
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next, ...rest) => {
  const toName = to.matched[0]?.name
  const fromName = from.matched[0]?.name
  
  if(toName == fromName) {
    return next()
  }
  if(from.meta.preventBackNav && store.getters.isNavBlocked) {
    store.dispatch('toggleAlertVisiblity', {isAlertVisible: true})
    router.push(from.fullPath)
  }
  return next()
})
App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
  const slug = event.url.split('.com').pop();

  // We only push to the route if there is a slug present
  if (slug) {
    router.push(slug);
  }
});

getCurrentUser()

export default router
