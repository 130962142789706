import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5dd4392a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "banner-carousal" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { className: "dots" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_swiper, {
      class: "banner-carousal-holder",
      onSlideChange: _cache[0] || (_cache[0] = ($event: any) => ($data.current = $event.activeIndex))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.banners, (item) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, {
            key: item.name
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: item.url
              }, null, 8, _hoisted_2)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.banners, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.name,
          class: _normalizeClass({active: $data.current == i})
        }, null, 2))
      }), 128))
    ])
  ]))
}