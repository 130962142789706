<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :icon="close" default-href="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Exam</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="detail-page mobile">
			
			<div className='detail-holder'>
        <span>Details</span>
        <div class="details" v-html="getInstruction(instructionId)"></div>
				<div>
					<span>Choose your default language:</span>

          <ion-select style="background: #c4c4c4" :value="language_id" @change="changeLanguage({language_id: $event.target.value})">
            <ion-select-option v-for="l in availableLanguages" :key="l.id" :value="l.id">{{l.name}}</ion-select-option>
          </ion-select>
				</div>
        <div style="display: flex">
          <ion-checkbox v-model="isChecked" style="margin-right: 10px" />
          <span>I have read and understood the instruction provided here</span>
        </div>

				
			</div>
      <ion-button
        :disabled="isBundleLoading"
        expand='block'
        @click="onClick">
        <ion-spinner v-if="loading" color="light" name="lines-small"></ion-spinner>
        Start
      </ion-button>
    </ion-content>
  </ion-page>
</template>

<script>

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonCheckbox,
  IonSelect,
  IonSelectOption,
  alertController,
  IonSpinner
} from "@ionic/vue";
import { close } from 'ionicons/icons';
import { mapActions, mapState, mapGetters} from 'vuex'
import {getInstruction} from '../../constants/instructions'

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    IonCheckbox,
    IonSelect,
    IonSelectOption,
    IonSpinner
  },
  setup() {
    return {
      close,
      getInstruction,
    };
  },
  data() {
    return {
      loading: false,
      quiz: {
        questions: [],
      },
      isChecked: false
    }
  },
  computed: {
    ...mapGetters(['isBundleLoading']),
		...mapState({
			availableLanguages: state => state.exam.availableLanguages,
			language_id: state => state.exam.language_id,
		}),
    id() {
      return this.$route.params.examId
    },
    instructionId() {
      return this.$route.query.ins_id
    }
  },
  mounted() {
    this.getBundle({id: this.id})
  },
  methods: {
    ...mapActions(['getBundle']),
    ...mapActions({
			changeLanguage: 'exam/changeLanguage',
    }),
    async onClick() {
      if(!this.isChecked) {
        const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            message: 'Student must confirm that they have understood the instructions by checking the checkbox.',
            buttons: ['Ok'],
          });
        await alert.present();
        return
      }
      this.loading = true
      setTimeout(()=>{
        this.$router.replace(`/exam/running/${this.id}/?q=1`);
      }, 10)
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-page{
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
  padding-bottom: 20px;

	&.desktop {
		.detail-holder {
			margin: auto;
			width: 90vw;

			&>*{
				padding: 10px 20px;
			}
		}
	}
	.detail-holder{
		background-color: rgba(0,0,0,0.1);
		border-radius: 5px;
		box-shadow: 0 0 11px -10px black;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		font-size: 0.8em;
		line-height: 1.3em;
		white-space: pre-line;
		text-align: justify;
    margin: 8px;
    margin-top: 15px;

		&>*{
			padding: 10px;
		}
		&>span{
			text-align: center;
			border-bottom: 1px solid rgba(0,0,0,0.2);
			font-weight: bold;
		}
		&>.openui-button{
			margin: auto;
		}
	}
}

</style>
