<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/"></ion-back-button>
        </ion-buttons>
        <ion-title>{{itemDetail.name}}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <folder-container :items="topics" />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton
} from "@ionic/vue";
import { pin, walk, warning, wifi, wine, ellipsisHorizontalSharp, arrowForward } from 'ionicons/icons';
import { mapActions, mapGetters } from 'vuex';
import FolderContainer from '../components/FolderContainer.vue';

export default {
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButtons,
    IonBackButton,
    FolderContainer
  },
  setup() {
    return {
      pin, walk, warning, wifi, wine, ellipsisHorizontalSharp, arrowForward
    };
  },
  data() {
    return {
      topics: [],
      itemDetail: {}
    }
  },
  computed: {
    id() {
      return this.$route.params.subjectId
    }
  },
  methods: {
    ...mapActions(['getItems', 'getItemDetail']),
  },
  async mounted() {
    let itemDetail = await this.getItemDetail({id: this.id})
    
    let ids = itemDetail.child.map(it=> it.id)
    let topics = await this.getItems({ids})
    topics = topics.map(it=>({
      ...it,
      href: `/${it.id}/${it.slug || 'topics'}`,
      subtitle: it.child? `${it.child.length} items`:
                  it.childCount > 0?
                    `${it.childCount} items`:
                    `Practice papers`
    }))
    this.itemDetail = itemDetail
    this.topics = topics
  }
};
</script>

<style lang="scss" scoped>
</style>