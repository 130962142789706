<template>
  <div class="folder-container">
    <router-link v-for="item in items"
      :key="item.id" class="ion-activatable"  :to="item.href">
      <ion-avatar slot="start">
        <div v-if="item.hasNewItem" class="new-content"></div>
        <img src="/assets/icon/folder.png" />
      </ion-avatar>
      <ion-label>
        <h3>{{item.name}}</h3>
        <p v-if="item.subtitle">{{item.subtitle}}</p>
      </ion-label>
      <ion-ripple-effect></ion-ripple-effect>
    </router-link>
  </div>
</template>

<script>

import {
  IonLabel,
  IonAvatar,
  IonRippleEffect,
  IonNavLink
} from "@ionic/vue";

export default {
  name: 'FolderContainer',
  components: {
    IonLabel,
    IonAvatar,
    IonRippleEffect,
  },
  props: {
    items: {
      type: Array,
      default: ()=>([])
    }
  }
}
</script>

<style lang="scss" scoped>

.folder-container {
  display: grid;
  grid-template-columns: 1fr;
  padding: 10px;
  gap: 5px;
  &>a {
    text-transform: unset;
    text-decoration: none;
    height: unset;
    --background: unset;
    color: unset;
    align-items: center;
    display: flex;
    flex: 1;
    overflow: hidden;
    position: relative;
    border-radius: 5px;

    p{
      font-size: 0.7em;
    }
  }
}

.new-content {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #ff5e36;
  margin-top: 10px;
  margin-left: 4px;
  align-self: start;
}
</style>
