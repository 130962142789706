<template>
  <div style="display: none"></div>
</template>

<script>
import {
  loadingController
} from "@ionic/vue";
export default {
  _loading: null,
  async mounted() {
    // await this.presentLoading()
  },
  unmounted() {
    this.dismissLoading()
  },
  methods: {
    async presentLoading() {
      this._loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',
        });
        
      await this._loading.present();
    },
    async dismissLoading() {
      if(this._loading) {
        this._loading.dismiss()
      }
    }
  },
}
</script>