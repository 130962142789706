<template>
  <div class="story-slider">
    <div v-for="story in stories" :key="story.name" class="story-item">
      <router-link
        :to="`/quiz/start/${story.id}`">
        <div>
            <span className='info'>Quiz</span>
            <span className='date'>{{story.title.substring(5).split('-').reverse().join('/')}}</span>
        </div>
        <div>
          <ion-icon :icon="newspaperOutline" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { GET_BUNDLES_BY_TAG} from '../graphql/bundle'
import {
  IonIcon,
} from "@ionic/vue";
import {
  pin,
  newspaperOutline
} from "ionicons/icons";
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    IonIcon,
  },
  setup() {
    return {
      pin,
      newspaperOutline
    }
  },
  props: {
  },
  computed: {
    ...mapState({
      bundles: state=> state.content.bundlesByTag,
      loadingStatus: state => state.content.loadingStatus
    }),
    id(){
      return '4391b825-373e-4554-9b00-22ad20bccebd'
    },
    isLoading() {
      return this.loadingStatus[`${this.id}_5`] || false
    },
    stories() {
      let bundles = this.bundles?.[`${this.id}_5`] || []
      bundles.sort((a, b) => b.title.localeCompare(a.title))
      bundles = bundles.filter(b=> {
        let publish_at = (new Date(b.publish_at)).valueOf()
        return publish_at < Date.now()
      })
      return bundles
    }
  },
  watch: {
  },
  mounted() {
    let res = this.getBundlesByTag({
      tag_id: this.id,
      type: 5
    })
  },
  methods: {
    ...mapActions(['getBundlesByTag']),

  }
};
</script>

<style lang="scss" scoped>
.story-slider {
  display: flex;
  flex-direction: row;
	overflow: scroll;

  position: relative;
  margin-top: 20px;
  padding-left: 10px;
  padding-bottom: 15px;
  padding-right: 10px;

  max-width: calc(100vw - 0px);
  overflow-x: visible;
  gap: 15px;

  &::-webkit-scrollbar {
    display: none;
  }

  .story-item {
    flex: 1;
    display: inline-flex;
    background-color: #275ec5;
    min-width: 70px;
    height: 80px;
    display: flex;
    border-radius: 6px;
    margin-top: 5px;
    box-shadow: 0 0 1px 3px #81ecec;
    position: relative;
    font-size: 50px;
    flex-direction: row;

    div.info {
      position: absolute;
      bottom: -20px;
      font-size: 0.25em;
      text-align: center;
      width: 100%;
    }

    &.highlight, &:first-child {
      z-index: 5;
      box-shadow: 0 0 2px 2px #e74c3c;
      animation: pulse 1.2s ease-out infinite;
      // box-shadow: 0 0 10px -4px #000000;

      @keyframes pulse {
        0% { 
          box-shadow: 0 0 2px 2px #e74c3c;
        }
        20% {
          box-shadow: 0 0 2px 2px #e74c3c;
        }
        60% { 
          box-shadow: 0 0 2px 2px #f5aea6;
        }
      }
    }
    a{
      flex: 1;
      display: flex;
      text-decoration: none;
      font-size: 0.8em;
      flex-direction: column;
      justify-content: space-between;
      z-index: 10;
      color: white;

      &>div {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-size: 12px;
          font-weight: bolder;
          padding-right: 5px;
        }

        &:first-child {
          justify-content: left;
          padding-left: 5px;
        }
        &:last-child {
          padding-bottom: 10px;
        }
      }
    }
  }
}
</style>
