
import {
  alertController,
} from "@ionic/vue";


export const createConfirmationAlert = async (header:string, message:string) => {
  const alert = await alertController.create({
    header: header,
    message: message,
    buttons: [{
      text: 'Cancel',
      role: 'cancel'
    }, {
      text: 'Yes',
      role: 'ok'
    }],
  });

  await alert.present();
  const { role } = await alert.onDidDismiss();
  if(role == 'ok') {
    return true
  }
  return false
}