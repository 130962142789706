<template>
  <div style="display: none"></div>
</template>

<script>

import {
  alertController,
} from "@ionic/vue";
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'NavBlockAlert',
  props: {
    header: {
      type: String,
      default: 'Leave the quiz?'
    },
    message: {
      type: String,
      default: 'You will loose the progress'
    },
  },
  watch: {
    isAlertVisible: {
      handler() {
        if(this.isAlertVisible) {
          this.createAlert()
        }
      }
    }
  },
  computed: {
    ...mapGetters(['isAlertVisible'])
  },
  mounted() {
    this.toggleNavBlock({isBlocked: true})
    this.toggleAlertVisiblity({isAlertVisible: false})
  },
  methods: {
    ...mapActions(['toggleNavBlock', 'toggleAlertVisiblity']),
    async createAlert() {
      const alert = await alertController.create({
        header: this.header,
        message: this.message,
        buttons: [{
          text: 'Cancel',
          role: 'cancel'
        }, {
          text: 'Yes, leave',
          role: 'ok'
        }],
      });

      await alert.present();
      const { role } = await alert.onDidDismiss();
      this.toggleAlertVisiblity({isAlertVisible: false})
      if(role == 'ok') {
        this.toggleNavBlock({isBlocked: false})
        this.$router.back()
      }
    }
  }
}
</script>