<template>
  <div>
    <ion-title class="ion-padding-top">Flashcards</ion-title>
    <div v-if="flashcards.length == 0" style="margin-top: 20px; justify-content: center; display: flex;">
      <ion-spinner v-if="isLoading" />
      <span v-else>No content</span>
    </div>
    <div class="folder-holder">
      <router-link
        :to="`/flashcard/start/${flashcard.id}`"
        v-for="flashcard in flashcards" 
        :key="flashcard.id"
        class="ion-activatable">
        <ion-avatar slot="start">
          <img src="/assets/icon/flash-cards.png" />
        </ion-avatar>
        <ion-label>
          <h3>{{flashcard.title}}</h3>
          <p>{{flashcard.questions_aggregate.aggregate.count}} cards | easy</p>
        </ion-label>
        <ion-ripple-effect></ion-ripple-effect>
      </router-link>
      <ion-button
        v-if="!shouldShowAll && !isLessThanLimit"
        @click="shouldShowAll = true">
        {{allFlashcards.length - limit}} more. Show all
      </ion-button>
      <ion-button
        v-if="shouldShowAll && !isLessThanLimit"
        @click="shouldShowAll = false">
        Show less
      </ion-button>
    </div>
  </div>
</template>

<script>

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonLabel,
  IonAvatar,
  IonRippleEffect,
  IonSpinner,
  IonBackButton
} from "@ionic/vue";
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
  components: {
    IonTitle,
    IonLabel,
    IonAvatar,
    IonRippleEffect,
    IonSpinner,
    IonButton
  },
  data() {
    return {
      shouldShowAll: false,
      limit: 3,
      // flashcards: []
    }
  },
  computed: {
    ...mapGetters({
      completedFlashcards: 'flashcard/completedFlashcards'
    }),
    ...mapState({
      _flashcards: state=> state.content.bundlesByTag,
      loadingStatus: state => state.content.loadingStatus,
    }),
    isLoading() {
      return this.loadingStatus[`${this.id}_10`] || false
    },
    allFlashcards() {
      let flashcards = this._flashcards?.[`${this.id}_10`] || []
      console.log(this.completedFlashcards)
      flashcards.sort((a, b) => a.publish_at.localeCompare(b.publish_at))
      return flashcards
    },
    flashcards() {
      if(this.shouldShowAll) {
        return this.allFlashcards
      }
      return this.allFlashcards.filter((f, i) => i < this.limit)
    },
    isLessThanLimit() {
      return this.allFlashcards.length < this.limit
    },
    id() {
      return this.$route.params.topicId
    },
  },
  async mounted() {
    this.getBundlesByTag({tag_id: this.id, type: 10})
  },
  methods: {
    ...mapActions(['getBundlesByTag']),
  },
}
</script>

<style lang="scss" scoped>
.folder-holder {
  display: grid;
  grid-template-columns: 1fr;
  padding: 10px;
  gap: 5px;
  &>a {
    text-transform: unset;
    text-decoration: none;
    height: unset;
    --background: unset;
    color: unset;
    align-items: center;
    display: flex;
    flex: 1;
    overflow: hidden;
    position: relative;
    border-radius: 5px;

    p{
      font-size: 0.7em;
    }
  }
  ion-avatar {
    --border-radius: unset;
    img {
      padding: 10px;
    }
  }
}

</style>