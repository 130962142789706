<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar style="--background: #252525; color: white">
        <ion-title>Account</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" style="--padding-top: 10px;--padding-start: 10px;--padding-end: 10px;">
      <div style="padding: 30px 0px; display: grid; gap: 5px;">
        <span>Logged in as: <b>{{ user?.displayName }}</b></span>
        <ion-button expand="block" color="primary" @click="logout">Sign Out</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton
} from "@ionic/vue";
import { ellipse, square, menu } from "ionicons/icons";

import { signOut, updateProfile } from "firebase/auth";
import { mapState} from "vuex";


import {auth, db} from "../../firebase"

export default {
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButton,
    // IonMenuButton,
    // IonItemDivider,
  },
  setup() {
    return {
      ellipse,
      square,
      menu,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },
  methods: {
    async logout() {
      await signOut(auth)
      window.localStorage.removeItem('vuex')
      window.location.reload()
      // this.$router.push('/login')

    }
  },
};
</script>