
import { IonApp, IonRouterOutlet, isPlatform, IonSpinner} from '@ionic/vue';
import { defineComponent, watch } from 'vue';
import SideMenu from './components/SideMenu.vue';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { FCM } from "@capacitor-community/fcm";
import { mapState, mapGetters } from 'vuex';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    SideMenu,
    IonSpinner,
  },
  data() {
    return {
      isAndroid: isPlatform('android'),
    }
  },
  computed: {
    ...mapState({
      isLoading: (state:any)=> state.user.isLoading,
      isAuth: (state:any)=> state.user.isAuth
    })
  },
  watch: {
    isLoading: {
      handler(isLoading) {
        if(isLoading) {
          return
        }
        if (this.isAuth) {
          // Skip 
          if(this.$route.meta.shouldAuth === false) {
            this.$router.push(this.$route.meta.redirect as string || '')
          }
        }else{
          this.$router.push('/login')
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if(isPlatform('mobileweb') || isPlatform('desktop') ) {
      return 0
    }
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    })
    .catch(err=>{
      console.log(err)
    })

    PushNotifications.addListener('registration', (token: Token) => {
      // alert('Push registration success, token: ' + token.value);

      // FCM.subscribeTo({ topic: "development" })
      // .then((r) => alert(`subscribed to topic: development`))
      // .catch((err) => console.log(err));
      // FCM.setAutoInit({ enabled: true }).then(() => console.log(`Auto init enabled`));
    });
    PushNotifications.addListener('registrationError', (error: any) => {
      // alert('Error on registration: ' + JSON.stringify(error));
    });

    // PushNotifications.addListener(
    //   'pushNotificationReceived',
    //   (notification: PushNotificationSchema) => {
    //     alert('Push received: ' + JSON.stringify(notification));
    //   },
    // );

  }
});
