import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_menu = _resolveComponent("side-menu")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_side_menu),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_ion_spinner, {
            key: 0,
            style: {"display":"block","margin":"auto","margin-top":"40vh"}
          }))
        : (_openBlock(), _createBlock(_component_ion_router_outlet, {
            key: 1,
            id: "main",
            animated: false
          }))
    ]),
    _: 1
  }))
}