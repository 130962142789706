<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar style="--background: #252525; color: white">
        <ion-title>Functions</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content style="--padding-top: 10px;--padding-start: 10px;--padding-end: 10px;">

      <ion-spinner
        style="display: block; margin: auto; margin-top: 20px"
        name="crescent" v-if="loading"></ion-spinner>
      <div v-if="!loading && functions.length == 0" style="text-align: center; margin-top: 10px;">No functions found</div>
      <div v-for="(f, i) in functions" :key="i" class="bundle-item">
        <div style="display: flex; flex-direction: column; gap: 4px; flex: 1">
          <span>{{f.name}}</span>
          <span style="font-size: 0.6em">{{f.functionUrl}}</span>
        </div>
        <ion-button fill="clear" color="dark" @click="copyUrl(f.functionUrl)">
          <ion-icon :icon="copyOutline"></ion-icon>
        </ion-button>
        <ion-button v-if="f.status == 'ERROR'" fill="clear" color="danger" >
          <ion-icon :icon="warningOutline"></ion-icon>
        </ion-button>
        <ion-button v-if="f.status == 'COMPLETED'" fill="clear" color="success" >
          <ion-icon :icon="checkmarkDone"></ion-icon>
        </ion-button>
        <ion-button fill="clear" color="dark" @click="$router.push({name: 'app-maker-function-file-edit', params: {id: f.id}})">
          <ion-icon :icon="pencilSharp"></ion-icon>
        </ion-button>
        <ion-button title="Deploy" v-if="f.status != 'QUEUED' && f.status != 'STARTED'" fill="clear" color="dark" @click="deploy(f.id)">
          <ion-icon :icon="rocketOutline"></ion-icon>
        </ion-button>
        <ion-button title="In queue" v-if="f.status == 'QUEUED'" fill="clear" color="dark">
          <ion-spinner name="dots" />
        </ion-button>
        <ion-button title="Deploying" v-if="f.status == 'STARTED'" fill="clear" color="dark">
          <ion-spinner name="circular" />
        </ion-button>
        <!-- <ion-button fill="clear" color="dark" @click="()=>openModal(f)" >
          <ion-icon :icon="ellipsisVertical"></ion-icon>
        </ion-button> -->
      </div>
      <ion-fab vertical="bottom" horizontal="end">
        <ion-fab-button @click="$router.push({name: 'app-maker-function-file-create'})">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>

    <ion-modal
      :is-open="isModalOpen"
      @didDismiss="isModalOpen = false"
      initial-breakpoint="0.6">
      <ion-header class="ion-no-border">
        <ion-toolbar>
          <ion-title>{{modal.title}}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item-divider style="min-height: 0"></ion-item-divider>
        <ion-item lines="none" class="disabled">
          <ion-icon :icon="copy" slot="start"></ion-icon>
          <ion-label>Create a copy</ion-label>
        </ion-item>
        <ion-item lines="none" class="disabled">
          <ion-icon :icon="clipboard" slot="start"></ion-icon>
          <ion-label>Copy secure share link</ion-label>
        </ion-item>

        <ion-item-divider style="min-height: 0"></ion-item-divider>
        <ion-text color="dark">
          <h5 style="margin-left: 15px">Downloads</h5>
        </ion-text>
        <ion-item lines="none" @click="()=>generatePDF(modal)">
          <ion-spinner name="crescent" slot="start" v-if="isGeneratingQuestionPDF"></ion-spinner>
          <ion-label>Question PDF</ion-label>
        </ion-item>
        <ion-item lines="none" class="disabled">
          <ion-label>Solution PDF</ion-label>
        </ion-item>
        <ion-item lines="none" class="disabled">
          <ion-label>OCR Sheet</ion-label>
        </ion-item>
        
        <ion-toast
          :is-open="isToastOpen"
          message="File saved in your Download folder."
          :duration="5000"
          @didDismiss="isToastOpen = false"
        >
        </ion-toast>
      </ion-content>
    </ion-modal>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonIcon,
  IonButton,
  IonModal,
  IonItemDivider,
  IonLabel,
  IonItem,
  IonText,
  IonSpinner,
  IonToast,
  IonFab,
  IonFabButton,
  toastController,
} from "@ionic/vue";
import { defineComponent } from 'vue';

import gql from "graphql-tag";
import { ellipsisVertical, copy, clipboard, pencilSharp, add, rocketOutline, checkmarkDone, copyOutline, warningOutline } from "ionicons/icons";
import { mapGetters, mapState} from "vuex";
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';

import { doc, getDocs, collection, query, where, addDoc } from "firebase/firestore";

import {auth, db} from "../../../firebase"

const convertBlobToBase64 = (blob) => new Promise((resolve, reject)=> {
  const reader = new FileReader
  reader.onerror = reject
  reader.onload = () =>  resolve(reader.result)
  reader.readAsDataURL(blob)
})

export default defineComponent({
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    IonModal,
    IonItemDivider,
    IonLabel,
    IonItem,
    IonText,
    IonSpinner,
    IonToast,
    IonFab,
    IonFabButton,
  },
  setup() {
    return {
      ellipsisVertical,
      copy,
      clipboard,
      pencilSharp,
      add,
      rocketOutline,
      checkmarkDone,
      copyOutline,
      warningOutline
    }
  },
  data() {
    return {
      functions: [],
      bundles: [],
      modal: {
        title: "",
        content: "",
        id: ""
      },
      isModalOpen: false,
      selectedLanguageId: '0f506717-ee80-44f1-b11b-0ddf15d8d559',
      isGeneratingQuestionPDF: false,
      loading: false,
      isToastOpen: false,
      loop: null
    }
  },
  computed: {
    ...mapGetters({
      'userId': 'user/userId'
    }),
    ...mapState({
      currentProject: state=>state.account.currentProject,
      user: state=>state.user.user
    })
  },
  async ionViewDidEnter() {
    this.loading = true
    const functionRef = collection(db, `user/${this.user.uid}/function`)
    const q = query(functionRef, where("projectId", "==", this.currentProject.id));
    const functionsSnap = await getDocs(q);

    let functions = []
    functionsSnap.docs.forEach(f=> {
      const data = f.data()
      functions.push({
        id: f.id,
        ...data,
        functionUrl: `https://us-central1-${this.currentProject.firebaseProjectId}.cloudfunctions.net/${data.name}`
      })
    })
    this.functions = functions

    this.checkDeployStatus()

    // const GET_BUNDLES = gql`
    //   query {
    //     question_bundles( where: {owner_id: {_eq: "${this.userId}"}} ) {
    //       id
    //       title
    //       questions {
    //         id
    //       }
    //     }
    //   }
    // `;
    // const { data } = await this.$apollo.query({
    //   query: GET_BUNDLES,
    //   fetchPolicy: "network-only"
    // });
    // this.bundles = data.question_bundles
    this.loading = false
  },
  ionViewWillLeave() {
    clearTimeout(this.loop)
  },
  methods: {
    async copyUrl(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.presentToast( 'URL copied to clipboard' )
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },
    async checkDeployStatus() {
      console.log('checking')
      const deployRef = collection(db, `deploy-queue`)
      const qq = query(deployRef, where("projectId", "==", this.currentProject.id));
      const deploySnap = await getDocs(qq);
      let deploys = []
      deploySnap.docs.forEach(f=> {
        deploys.push({
          id: f.id,
          ...f.data()
        })
      })

      deploys = deploys.filter(d=> d.createdAt > Date.now() - 10 * 60*1000)
      
      deploys.sort( ( a, b ) =>  b.createdAt - a.createdAt);

      let ff = this.functions.map(f=>{
        let d = deploys.find(dp=> dp.functionId == f.id)
        if(d) {
          return {
            ...f,
            status: d.status
          }
        }
        return f
      })
      this.functions = ff
      // if any job not complete, then loop
      let runningJob = ff.find(f=> f.status == 'QUEUED' || f.status == 'STARTED')

      if(runningJob) {
        clearTimeout(this.loop)
        this.loop = setTimeout(async ()=>{
          this.checkDeployStatus()
        }, 10*1000)
      }else {
        console.log('No job pending')
      }

    },
    openModal(data) {
      this.modal.title = data.title;
      this.modal.id = data.id;
      this.modal.content = data.questions.length;
      this.isModalOpen = true;
    },
    async deploy(functionId) {
      this.presentToast("Deploying function")
      const deployQueueRef = collection(db, `deploy-queue`)
      const q = query(deployQueueRef, where("functionId", "==", functionId));
      let que = []
      let queSnap = await getDocs(q)
      queSnap.docs.forEach(s=>{
        que.push(s.data())
      })
      que = que.filter(q=> q.status != 'COMPLETED' && q.status != 'ERROR')
      if(que.length) {
        this.presentToast("Already waiting in the queue")
        return
      }
      const docRef = await addDoc(collection(db, `deploy-queue`), {
        functionId: functionId,
        projectId: this.currentProject.id,
        userId: this.user.uid,
        status: 'QUEUED',
        createdAt: Date.now()
      });

      this.checkDeployStatus()
      this.presentToast("Function deployment queued. Usually takes 10min")
      // try{
      //   let res = await fetch(`https://api2.doorie.in/deploy?functionId=${functionId}`, {
      //     headers: {
      //       "Authorization":  `Bearer ${window.localStorage.getItem('firebase-token') || ''}`
      //     }
      //   })
      //   res = await res.json()
      //   this.presentToast("Function deployed")
      // }catch(e) {
      //   this.presentToast("Function deployment failed")
      // }
    },
    async presentToast(message, duration=3500) {
      const toast = await toastController.create({
        message,
        duration,
        position: 'bottom'
      });
      await toast.present();
    },
    async generatePDF(data) {
      this.isGeneratingQuestionPDF = true;
      const GET_BUNDLE = gql`
        query {
          question_bundles_by_pk(id: "${data.id}") {
            id
            title
            questions {
              id
              answer
              translations {
                id
                language_id
                question
                a
                b
                c
                d
              }
            }
          }
        }
      `;
      try{
        const { data } = await this.$apollo.query({
          query: GET_BUNDLE,
        });
        const questions = data.question_bundles_by_pk.questions

        let html = questions.map(q=>{
          let translation = q.translations.find(t=>t.language_id == this.selectedLanguageId)
          return `
            <div class="question-item">
              <p>${translation.question}</p>
              <p>${translation.a}</p>
              <p>${translation.b}</p>
              <p>${translation.c}</p>
              <p>${translation.d}</p>
            </div>
          `
        }).join('')
        
        const style = `
          <style>
            body {
              font-family: 'Roboto', sans-serif;
              font-size: 14px;
              display: flex;
            }
            .question-item {
              display: flex;
              flex-direction: column;
              gap: 4px;
              background: rgba(245, 245, 245, 1);
              border-radius: 6px;
              padding: 15px;
              margin-bottom: 10px;
            }
          </style>
        `
        const res = await fetch("https://dangal-pdf-render.herokuapp.com/render", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "html": `<div style="display: flex; flex-direction: column; gap: 10px;">${html}</div>`,
            style
          })
        })
        const blob = await res.blob();
        
        const _URL = window.URL || window.webkitURL
        var url = _URL.createObjectURL(blob);


        let base64 = await convertBlobToBase64(blob)

        const savedFile = await Filesystem.writeFile({
          data: base64,
          path: `Download/question_bundle.pdf`,
          directory: Directory.ExternalStorage,
        })

        this.isToastOpen = true

        // Sharing is working
        // await Share.share({
        //   title: 'See cool stuff',
        //   text: 'Really awesome thing you need to see right meow',
        //   url: blob,
        //   dialogTitle: 'Share with buddies',
        // });

        this.isGeneratingQuestionPDF = false;
      }catch(e){
        console.log(e);
        alert(e)
      }
    },
  }
})
</script>

<style lang="scss" scoped>
  .bundle-item {
    margin: 10px;
    background: #DCE2FF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    padding-right: 10px;
    border-radius: 6px;
  }
  ion-item.disabled {
    opacity: 0.5;
  }
</style>
