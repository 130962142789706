import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, { color: "primary" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_buttons, { slot: "end" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: $setup.ellipsisHorizontalSharp }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_title, null, {
            default: _withCtx(() => [
              _createTextVNode("Recently added")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, {
        routerDirection: "forward",
        href: "/full-test/topics",
        class: "ion-activated"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            icon: $setup.arrowForward,
            slot: "end"
          }, null, 8, ["icon"]),
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode("Full SSC Mock test")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, {
        routerDirection: "forward",
        href: "/learn-gk/topics"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            icon: $setup.arrowForward,
            slot: "end"
          }, null, 8, ["icon"]),
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode("Learn GK")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}