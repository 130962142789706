
const recent = {
  slug: '',
  attempted_at: Date.now(),
  path: [],
  state: ''
}
const dummy = (state:string, n = 10) => {
  const d = []
  for(let i=0; i<n; i++) {
    d.push({
      slug: '/abc/jkf/jhs',
      attempted_at: Date.now(),
      path: ['science', 'physics'],
      state
    })
  }
  return d
}
export default {
  state () {
    return {
      data: [...dummy('home')]
    }
  },
  mutations: {
    // SET_THEME(state:any, theme:any) {
    //   state.theme = theme
    // }
  },
  actions: {
    // setTheme({ commit }:any, theme:string) {
    //   commit('SET_THEME', theme)
    // },
  },
  getters: {
    data (state:any) {
      return state.data
    }
  },
}