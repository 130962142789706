<template>
  <ion-modal 
    :is-open="modelValue"
    :breakpoints="[0.6, 0.9]"
    :initialBreakpoint="0.6"
    @didDismiss="$emit('update:modelValue', false)">
    <ion-content>
      <ion-header>
        <ion-toolbar>
          <ion-title>Summary</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="$emit('update:modelValue', false)">
              <ion-icon name='close' />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
        <div style="display: flex; justify-content: center; margin-top: 15px">
          <ion-button
            color='danger' fill='solid'
            @click="$emit('submit');">Submit</ion-button>
        </div>
        <div style="display: flex">
          <table border-spacing='10px'>
            <tr><td>Section</td> <td>All</td></tr>
            <tr><td># questions</td> <td>{{totalQuestions}}</td></tr>
            <tr><td>Attempted</td> <td>{{attempted.length}}</td></tr>
            <tr><td>Not answered</td> <td>{{totalQuestions - attempted.length}}</td></tr>
            <tr><td>Marked for review</td> <td>{{bookmarks.length}}</td></tr>
            <tr><td>Answered and Marked for review</td> <td>{{markedAndAnswered.length}}</td></tr>
            <tr><td>Not visited</td> <td>{{totalQuestions - visited.length}}</td></tr>
          </table>
        </div>
    </ion-content>
  </ion-modal>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonIcon,
  IonModal
} from "@ionic/vue";
import {mapState, mapGetters} from 'vuex'

export default {
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonIcon,
    IonModal
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      attempted: 'exam/attempted',
      markedAndAnswered: 'exam/markedAndAnswered'
    }),
    ...mapState({
      isRightSliderOpen: state=> state.exam.isRightSliderOpen,
      bookmarks: state=> state.exam.bookmarks,
      visited: state=> state.exam.visited,
      answers: state=> state.exam.answers,
      sessionId: state=> state.exam.session_id,
      totalQuestions: state=> state.exam.totalQuestions
    }),
  }
}
</script>

<style lang="scss" scoped>
table {
  flex: 1;
  margin: 10px;
  border-collapse: separate;
  border: 1px solid #c4c4c4;
  td {

    &:first-child {
      background: #e5f6fd;
    }
    padding: 5px;
    border-bottom: 1px solid #e4e4e4;
  }
}
</style>
