
import ApolloClient from 'apollo-boost'

const getHeaders = () => {
  const headers = {
    // 'x-hasura-admin-secret': '5UkID5nVvI9NTZtyhIr5zV2FF27gzNXzUvoEn2Vi0N3PaDliwUMOEvmaook4XXNQ'
  } as any;
  const token = window.localStorage.getItem('firebase-token');
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }
  return headers;
};

const apolloClient = new ApolloClient({
  // You should use an absolute URL here
  // uri: import.meta.env.VITE_APP_GRAPHQL_URI,
  uri: 'https://one-seal-88.hasura.app/v1/graphql',
  // uri: 'http://34.131.26.129/v1/graphql',
  headers: getHeaders(),
  request: (operation) => {
    operation.setContext({
      headers: getHeaders(),
    });
  },
})

export default apolloClient