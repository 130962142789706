<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar style="--background: #252525; color: white">
        <ion-title>Function: {{name}}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content style="--padding-top: 10px;--padding-start: 10px;--padding-end: 10px;">
      <ion-item>
        <ion-label style="font-weight: bold">Function name</ion-label>
        <ion-input v-model="name" placeholder="Enter name"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label style="font-weight: bold; max-width: 120px">API type</ion-label>
        <ion-select v-model="type" placeholder="Select API type">
          <ion-select-option value="REQUEST">API request</ion-select-option>
          <ion-select-option value="onCreate">onCreate</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item v-if="triggers.includes(type)">
        <ion-label style="font-weight: bold">Collection name</ion-label>
        <ion-input v-model="collectionName" placeholder="Enter collection name"></ion-input>
      </ion-item>

      <ion-segment v-model="editorTab" style="max-width: 500px">
        <ion-segment-button value="basic-code-editor">
          <ion-label>Code editor</ion-label>
        </ion-segment-button>
        <ion-segment-button value="scratch">
          <ion-label>Visual editor</ion-label>
        </ion-segment-button>
      </ion-segment>
      <div>
        <div v-if="editorTab == 'basic-code-editor'">
          <code-editor v-model="code" />
        </div>
        <div v-if="editorTab == 'scratch'" style="display: grid; grid-template-columns: 1fr 1fr;">
          <blockly-test v-model="blocklyJson" @code="onCode" />
          <code-editor  v-model="code" />
        </div>
      </div>

      
      <ion-button @click="save">Save</ion-button>
      <ion-title>Deployment history</ion-title>
      <ion-list
        v-for="(d, i) in deployments" :key="i">
        <span>{{d.status}}</span>
        <span slot="end">{{d.runtime}}</span>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonInput,
  IonButton,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  toastController,
  IonList,
  onIonViewWillEnter,
  IonSegment,
  IonSegmentButton,
} from "@ionic/vue";

import { doc, getDoc, collection, query, where, addDoc, updateDoc, getDocs } from "firebase/firestore";

import {auth, db} from "../../../firebase"
import CodeEditor from '../../../components/CodeEditor.vue';
import { mapGetters, mapState } from 'vuex';
import BlocklyTest from '../../../components/BlocklyTest.vue';

export default defineComponent({
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonInput,
    IonButton,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    CodeEditor,
    IonList,
    BlocklyTest,
    IonSegment,
    IonSegmentButton,
  },
  setup() {
    return {
      triggers: ['onCreate']
    }
  },
  data() {
    return {
      name: '',
      type: 'REQUEST',
      code: '',
      collectionName: '',
      deployments: [],
      editorTab: 'basic-code-editor',
      blocklyJson: ''
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      currentProject: state => state.account.currentProject,
    }),
    id() {
      return this.$route.params.id
    }
  },
  watch: {
    editorTab: {
      handler() {
        console.log(this.editorTab)
      }
    },
    blocklyJson: {
      handler() {
        // console.log(this.blocklyJson)
      }
    }
  },
  async mounted() {
    if(!this.id) {
      return
    }
    const functionSnap = await getDoc(doc(db, `user/${this.user.uid}/function`, this.id));
    const data = functionSnap.data()

    this.name = data.name
    this.type = data.type || 'REQUEST'
    this.code = data.code
    this.blocklyJson = data.blocklyJson
    this.collectionName = data.collectionName || ''
    this.getDeployements()
  },
  ionViewWillEnter() {
    this.getDeployements()
  },
  methods: {
    async getDeployements() {
      const deployRef = collection(db, `deploy-queue`)
      const qq = query(deployRef, where("functionId", "==", this.id));
      const deploySnap = await getDocs(qq);
      let deploys = []
      deploySnap.docs.forEach(f=> {
        deploys.push({
          id: f.id,
          ...f.data()
        })
      })

      deploys.sort( ( a, b ) =>  b.createdAt - a.createdAt);
      this.deployments = deploys
    },
    async presentToast(message, duration=1500) {
      const toast = await toastController.create({
        message,
        duration,
        position: 'bottom'
      });
      await toast.present();
    },
    onCode(code) {
      this.code = code
    },
    async save() {
      if(!this.id) {
        const docRef = await addDoc(collection(db, `user/${this.user.uid}/function`), {
          name: this.name,
          type: this.type,
          code: this.code,
          collectionName: this.collectionName,
          projectId: this.currentProject.id
        });
        this.$router.replace({
          name: 'app-maker-function-file-edit', params: {id: docRef.id}
        })
      }else{
        const functionRef = doc(db, `user/${this.user.uid}/function`, this.id);
        await updateDoc(functionRef, {
          name: this.name,
          type: this.type,
          code: this.code,
          collectionName: this.collectionName,
          blocklyJson: this.blocklyJson
        })
      }
      this.presentToast(`Function saved`)
    }
  }
})
</script>
