<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar style="--background: #252525; color: white">
        <!-- <ion-title>Project</ion-title> -->
        <ion-select
          style="--padding-start: 30px"
          :value="currentProject.id"
          placeholder="Select project"
          @ionChange="chooseProject" slot="start">
          <ion-select-option
            v-for="(p, i) in projects" :key="i" :value="p.id">{{p.name}}</ion-select-option>
        </ion-select>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-tabs v-if="projects.length" :class="`home-tabs-holder ${isDesktop?'desktop':''}`">
        <ion-router-outlet mode="md" />
        <ion-tab-bar slot="bottom" :color="isDesktop?'dark':null">
          <ion-tab-button tab="home" href="/app-maker/dashboard">
            <ion-img style="height: 25px" src="/assets/icon/menu/dashboard.png" />
            <ion-label>Dashboard</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="functions" href="/app-maker/files">
            <ion-img style="height: 25px" src="/assets/icon/menu/files.png" />
            <ion-label>Files</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="setting" href="/app-maker/setting">
            <ion-img style="height: 25px" src="/assets/icon/menu/setting.png" />
            <ion-label>Setting</ion-label>
          </ion-tab-button>
          <div style="flex: 1"></div>
          <ion-tab-button tab="account" href="/app-maker/account">
            <ion-img style="height: 25px" src="/assets/icon/menu/account.png" />
            <ion-label>Account</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
      <div v-else>
        <ion-title>Create project</ion-title>
        <ion-item>
          <ion-label>Project name</ion-label>
          <ion-input v-model="newProjectName" placeholder="enter text" />
        </ion-item>
        <ion-button @click="createProject">Create</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="js">
import { addDoc, collection, getDocs } from "@firebase/firestore";
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonImg,
  IonPage,
  IonRouterOutlet,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSelect,
  IonSelectOption,
  isPlatform,
  IonItem,
  IonInput,
  IonButton
} from "@ionic/vue";
import {
  ellipse,
  square,
  triangle,
  homeSharp,
  trendingUpOutline,
  barbell,
  albums,
  analyticsSharp
} from "ionicons/icons";
import { mapActions, mapState } from "vuex";
import { db } from "../../firebase";

export default {
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonImg,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSelect,
    IonSelectOption,
    IonRouterOutlet,
    IonItem,
    IonInput,
    IonButton
  },
  setup() {
    return {
      ellipse,
      square,
      triangle,
      homeSharp,
      trendingUpOutline,
      barbell,
      albums,
      analyticsSharp,
      isDesktop: isPlatform("desktop") && !isPlatform("android"),
    };
  },
  data() {
    return {
      newProjectName: ''
    }
  },
  computed: {
    ...mapState({
      currentProject: state=>state.account.currentProject,
      projects: state=>state.account.projects,
      user: state=>state.user.user,
      isAuth: state=>state.user.isAuth
    })
  },
  watch: {
    isAuth: {
      handler() {
        this.resetCurrentProject()
      }
    }
  },
  async mounted() {
    await this.resetCurrentProject()
  },
  methods: {
    ...mapActions({
      setProject: 'account/setProject',
      setProjects: 'account/setProjects'
    }),
    async resetCurrentProject() {
      let currentProject = this.currentProject
      await this.loadProjects()
      if(this.projects.length && !currentProject.id) {
        currentProject = this.projects[0]
      }
      this.setProject(currentProject)
    },
    chooseProject(e) {
      let project = this.projects.find(p=> p.id === e.detail.value)
      if(project) {
        this.setProject(project)
      }
      window.location.reload()
    },
    async createProject() {
      if(!this.user) {
        return
      }
      const docRef = await addDoc(collection(db, `user/${this.user.uid}/project`), {
        name: this.newProjectName
      });
      window.location.reload()
    },
    async loadProjects() {
      if(!this.isAuth) {
        return
      }
      const projectsSnap = await getDocs(collection(db, `user/${this.user.uid}/project`));
      let projects = []
      projectsSnap.docs.forEach(f=> {
        projects.push({
          id: f.id,
          ...f.data()
        })
      })
      this.setProjects(projects)
    }
  }
};
</script>

<style lang="scss" scoped>
.home-tabs-holder{
  &.desktop {
    display: flex;
    flex-direction: row-reverse !important;

    ion-tab-bar {
      width: 90px;
      display: flex;
      flex-direction: column;
      height: unset;
      justify-content: flex-start;
      // background: #3780ff;
      ion-tab-button{
        width: 100%;
        max-height: 80px;
        background: transparent;
        color: white;
      }
    }
  }
}
</style>
