<template>
<div>
  <ion-spinner v-if="!isLoaded" />
  <BlocklyComponent id="blockly1" :options="options" ref="foo"></BlocklyComponent>

  <ion-button @click="emitCode">Generate Code</ion-button>
  <ion-button @click="cleanEditor">Clean</ion-button>
  <p id="code">
    <button v-on:click="showCode()">Show JavaScript</button>
    <pre v-html="code"></pre>
  </p>
</div>
</template>

<script>
import {
  IonButton,
  IonSpinner
} from "@ionic/vue";
import Blockly from 'blockly';
import {javascriptGenerator} from 'blockly/javascript';
import BlocklyComponent from './BlocklyComponent.vue';
import * as A from '../blocks/block';
export default {
  components: {
    BlocklyComponent,
    IonSpinner,
    IonButton
  },
  setup() {
    return {
      hey: 1
    }
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    } 
  },
  data() {
    return {
      code: '',
      isLoaded: false,
      options: {
        media: 'media/',
        grid: {
          spacing: 25,
          length: 3,
          colour: '#ccc',
          snap: true
        },
        toolbox:
        `<xml>
          <category name="Logic" colour="%{BKY_LOGIC_HUE}">
            <block type="controls_if"></block>
            <block type="logic_compare"></block>
            <block type="logic_operation"></block>
            <block type="logic_negate"></block>
            <block type="logic_boolean"></block>
          </category>
          <category name="Loops" colour="%{BKY_LOOPS_HUE}">
            <block type="controls_repeat_ext">
              <value name="TIMES">
                <block type="math_number">
                  <field name="NUM">10</field>
                </block>
              </value>
            </block>
            <block type="controls_whileUntil"></block>
          </category>
          <category name="Math" colour="%{BKY_MATH_HUE}">
            <block type="math_number">
              <field name="NUM">123</field>
            </block>
            <block type="math_arithmetic"></block>
            <block type="math_single"></block>
          </category>
          <category name="Text" colour="%{BKY_TEXTS_HUE}">
            <block type="text"></block>
            <block type="text_length"></block>
            <block type="text_print"></block>
          </category>
          <category name="Variables" custom="VARIABLE" colour="%{BKY_VARIABLES_HUE}">
          </category>
          <category name="Firebase" colour="%{BKY_LOOPS_HUE}">
            <block type="get_documents"></block>
            <block type="geo_point"></block>
            <block type="get_document"></block>
            <block type="create_document"></block>
            <block type="update_document"></block>
          </category>
          <category name="API" colour="%{BKY_LOOPS_HUE}">
            <block type="controller"></block>
            <block type="array"></block>
            <block type="array_item"></block>
            <block type="object"></block>
            <block type="object_item"></block>
            <block type="variable_declare"></block>
            <block type="get_request"></block>
            <block type="api_resolve"></block>
            <block type="to_statement"></block>
          </category>
        </xml>`
      }
    }
  },
  mounted() {
    setTimeout(()=>{
      let workspace = JSON.parse(this.modelValue || '{}')
      this.$refs["foo"].workspace.addChangeListener(this.eventListener)
      try{
        Blockly.serialization.workspaces.load(workspace, this.$refs["foo"].workspace)
      }catch(e) {console.log(67)}
      this.isLoaded = true
    }, 2000)
  },
  beforeUnmount() {
    this.$refs["foo"].workspace.removeChangeListener(this.eventListener)
  },
  methods: {
    cleanEditor() {
      Blockly.serialization.workspaces.load({}, this.$refs["foo"].workspace)
    },
    eventListener(e) {
      const workspace = this.$refs["foo"].workspace
      let workspaceJson = Blockly.serialization.workspaces.save(workspace)
      this.$emit('update:modelValue', JSON.stringify(workspaceJson))
    },
    showCode() {
      const workspace = this.$refs["foo"].workspace
      let code = javascriptGenerator.workspaceToCode( workspace );
      if(code.substring(0, 3) == 'var') {
        let c = code.split('\n')
        c.shift()
        code = c.join('\n')
      }
    },
    emitCode() {
      const workspace = this.$refs["foo"].workspace
      let code = javascriptGenerator.workspaceToCode( workspace );
      if(code.substring(0, 3) == 'var') {
        let c = code.split('\n')
        c.shift()
        code = c.join('\n')
      }
      this.$emit('code', code)
    }
  }
}
</script>
<style lang="scss" scoped>
#blockly1 {
  position: relative;
  height: 500px;
}
</style>
