import Blockly from 'blockly';
import {javascriptGenerator} from 'blockly/javascript';
import './blockFirebase'

Blockly.Blocks['controller'] = {
  init: function() {
    this.jsonInit({
      "type": "controller",
      "message0": "Endpoint %1 at %2 %3 Body %4 Return %5",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "method",
          "options": [["GET","get"], ["POST","post"], ["PATCH","patch"]]
        },
        {
          "type": "field_input",
          "name": "url",
          "text": "/"
        },
        {
          "type": "input_dummy",
        },
        {
          "type": "input_statement",
          "name": "body"
        },
        {
          "type": "input_value",
          "check": 'String',
          "name": "return"
        }
      ],
      "inputsInline": false,
      "previousStatement": "controller",
      "nextStatement": "controller",
      "colour": 230,
      "tooltip": "tool tip",
      "helpUrl": "help url"
    })
  }
};

Blockly.Blocks['object'] = {
  init: function() {
    this.jsonInit({
      "type": "object",
      "message0": "Object %1 %2",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "input_statement",
          "name": "object"
        }
      ],
      "inputsInline": true,
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    })
  }
};

Blockly.Blocks['to_statement'] = {
  init: function() {
    this.jsonInit({
      "type": "to_statement",
      "message0": "%1",
      "args0": [
        {
          "type": "input_value",
          "name": "value"
        }
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    })
  }
};
Blockly.Blocks['array'] = {
  init: function() {
    this.jsonInit({
      "type": "object",
      "message0": "Array %1 %2",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "input_statement",
          "name": "array",
        }
      ],
      "inputsInline": true,
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    })
  }
};

Blockly.Blocks['array_item'] = {
  init: function() {
    this.jsonInit({
      "type": "array_item",
      "message0": "%1 %2 %3",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "type",
          "options": [["Quotes","quotes"], ["Plain","plain"]]
        },
        {
          "type": "field_input",
          "name": "value",
        },
        {
          "type": "input_value",
          "name": "input",
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
    })
  }
};



Blockly.Blocks['object_item'] = {
  init: function() {
    this.jsonInit({
      "type": "object",
      "message0": "%1: %2",
      "args0": [
        {
          "type": "field_input",
          "name": "key",
          "text": "key"
        },
        {
          "type": "input_value",
          "name": "value"
        }
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    })
  }
};

Blockly.Blocks['get_collection'] = {
  init: function() {
    this.jsonInit({
      "type": "get_collection",
      "message0": "docs from collection %1",
      "args0": [
        {
          "type": "field_input",
          "name": "collection_name",
          "text": "collection name",
        }
      ],
      "inputsInline": true,
      "output": "Array",
      "colour": 230,
      "tooltip": "tool tip",
      "helpUrl": "help url"
    })
  }
};
Blockly.Blocks['get_request'] = {
  init: function() {
    this.jsonInit({
      "type": "get_request",
      "message0": "get from request %1",
      "args0": [
        {
          "type": "field_input",
          "name": "param_name",
          "check": "String",
          "align": "CENTRE"
        }
      ],
      "inputsInline": true,
      "output": null,
      "colour": 230,
      "tooltip": "tool tip",
      "helpUrl": "help url"
    })
  }
};

Blockly.Blocks['api_resolve'] = {
  init: function() {
    this.jsonInit({
      "type": "api_resolve",
      "message0": "Resolve %1, send %2",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "status",
          "options": [["SUCCESS","200"], ["FAIL","404"]]
        },
        {
          "type": "input_value",
          "name": "object",
          "check": "String"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
    })
  }
};


Blockly.Blocks['variable_declare'] = {
  init: function() {
    this.jsonInit({
      "type": "variable_declare",
      "message0": "Declare %1 = %2",
      "args0": [
        {    // Beginning of the field variable dropdown
          "type": "field_variable",
          "name": "VAR",    // Static name of the field
          "variable": "%{BKY_VARIABLES_DEFAULT_NAME}"    // Given at runtime
        },
        {
          "type": "input_value",
          "name": "value"
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
    })
  }
};

javascriptGenerator['controller'] = function(block) {
  const method = block.getFieldValue('method');
  const url = block.getFieldValue('url');
  let statements_body = javascriptGenerator.statementToCode(block, 'body');
  let object_return = javascriptGenerator.valueToCode(block, 'return', javascriptGenerator.ORDER_ATOMIC);
  
  // TODO: Assemble JavaScript into code variable.
  var code = `app.${method}('${url}', async (req, res) => {
    ${statements_body}
    res.json(${object_return})
  })\n\n`;
  return code;
};
javascriptGenerator['object'] = function(block) {
  var value = javascriptGenerator.statementToCode(block, 'object');
  // TODO: Assemble JavaScript into code variable.
  let code = `{\n${value}}`;
  return [code, javascriptGenerator.ORDER_ATOMIC];
};

javascriptGenerator['to_statement'] = function(block) {
  var value = javascriptGenerator.valueToCode(block, 'value', javascriptGenerator.ORDER_ATOMIC);
  // TODO: Assemble JavaScript into code variable.
  let code = value;
  return code
};

javascriptGenerator['object_item'] = function(block) {
  var key = block.getFieldValue('key');
  var value = javascriptGenerator.valueToCode(block, 'value', javascriptGenerator.ORDER_ATOMIC);
  // TODO: Assemble JavaScript into code variable.
  let code = `${key}: ${value},\n`;
  return code;
};

javascriptGenerator['array'] = function(block) {
  var value = javascriptGenerator.statementToCode(block, 'array');
  let code = '[]'
  if(value) {
    code = `[${value.substring(0, value.length - 1)}]`
  }
  return [code, javascriptGenerator.ORDER_ATOMIC];
};

javascriptGenerator['array_item'] = function(block) {
  var value_type = block.getFieldValue('type')
  var value = block.getFieldValue('value')
  let input = javascriptGenerator.valueToCode(block, 'input', javascriptGenerator.ORDER_ATOMIC);
  // TODO: Assemble JavaScript into code variable.
  if(value_type === 'quotes') {
    value = `'${value}'`
  }
  if(input) {
    value = input
  }
  let code = `${value},`;
  return code
};

javascriptGenerator['get_collection'] = function(block) {
  var collection_name = block.getFieldValue('collection_name');
  // TODO: Assemble JavaScript into code variable.
  var code = `await (async () => {
                let snap = await admin.firestore()
                      .collection('${collection_name}').get()
                let data = []
                snap.forEach(doc => {
                  data.push(doc.data())
                });
                return data
              })()`;
  return [code, javascriptGenerator.ORDER_ATOMIC];
};

javascriptGenerator['get_request'] = function(block) {
  var name = block.getFieldValue('param_name');
  // TODO: Assemble JavaScript into code variable.
  var code = `req.params.${name} || req.query.${name} || req.body.${name}`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, javascriptGenerator.ORDER_ATOMIC];
};

javascriptGenerator['api_resolve'] = function(block) {
  let object = javascriptGenerator.valueToCode(block, 'object', javascriptGenerator.ORDER_ATOMIC);
  const status = block.getFieldValue('status');

  let code = `return res.status(${status}).json(${object}) \n`
  return code
}

javascriptGenerator['variable_declare'] = function(block) {
  let name = javascriptGenerator.nameDB_.getName(block.getFieldValue('VAR'), Blockly.Names.NameType.VARIABLE);
  let value = javascriptGenerator.valueToCode(block, 'value', javascriptGenerator.ORDER_ATOMIC);

  let code = `let ${name} ${value?'= ':''} ${value} \n`
  return code
}
