<template>
  <div class="question-item">
    <transition name="flip">
        <div v-if="!isAnswerVisible" class="question-holder">
          <p style="white-space: pre-wrap; text-align: center" v-html="processedQuestion.question"></p>
          <ion-button expand="full" @click="isAnswerVisible = true">
            Show answer
            <ion-icon :icon="arrowForward" style="margin-left: 15px;" />
          </ion-button>
        </div>
        <div v-else class="answer-holder">
          <p style="white-space: pre-wrap; padding-bottom: 0px; text-align: center;" v-html="processedQuestion.question"></p>
          <ion-item-divider style="min-height: 20px" />
          <p style="white-space: pre-wrap; padding-bottom: 10px; font-weight: bold; text-align: center" v-html="processedQuestion.a"></p>
          <p style="white-space: pre-wrap;" v-html="processedQuestion.explanation"></p>
          <ion-button @click="knewAnswer" expand="full" color="success">
            I knew the answer
          </ion-button>
          <ion-button @click="didNotKnowAnswer" expand="full" color="danger">
            I didn't know the answer
          </ion-button>
        </div>
    </transition>

    <div @click="isModalOpen=true" style="text-decoration: underline">Report error</div>

    <div>
      <span class="subtitle">{{masteredMessage}}</span>
      <ion-progress-bar :value="mastered/totalQuestions" color="success" style="height: 15px; border-radius: 3px"></ion-progress-bar>
    </div>
    <div>
      <span class="subtitle">{{revisionMessage}}</span>
      <ion-progress-bar :value="revising/totalQuestions" color="warning" style="height: 15px; border-radius: 3px"></ion-progress-bar>
    </div>
    <div>
      <span class="subtitle">{{learningMessage}}</span>
      <ion-progress-bar :value="learning/totalQuestions" color="danger" style="height: 15px; border-radius: 3px"></ion-progress-bar>
    </div>
    
    <report-error
      v-model:isOpen="isModalOpen"
      itemType="FLASHCARD"
      :itemId="current"
      description="If you find anything wrong with the questions, please report. This help us maintain the quality with our limited resources."
      buttonText="Report flashcard" />
  </div>
</template>

<script>
import {
  IonButton,
  IonIcon,
  IonProgressBar,
  IonItemDivider
} from "@ionic/vue";
import {mapActions, mapGetters, mapState} from 'vuex'
import { close, arrowForward } from 'ionicons/icons';
import { replaceImageNameWithURL } from '../../services/question'
import ReportError from '../../components/ReportError.vue';
export default {
  components: {
    IonButton,
    IonIcon,
    IonProgressBar,
    ReportError,
    IonItemDivider
  },
  setup() {
    return {
      close, arrowForward
    };
  },
  props: {
    question: {
      type: Object,
      default: ()=>({})
    }
  },
  data() {
    return {
      isAnswerVisible: false,
      isModalOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      mastered: 'flashcard/mastered',
      revising: 'flashcard/revising',
      learning: 'flashcard/learning',
    }),
    ...mapState({
      totalQuestions: state=> state.flashcard.totalQuestions
    }),
    masteredMessage() {
      return `You have mastered ${this.mastered} cards`
    },
    revisionMessage() {
      return `You are revising ${this.revising} cards`
    },
    learningMessage() {
      return `You have to learn ${this.learning} cards`
    },
    current() {
      return this.$route.query.q
    },
    id() {
      return this.$route.params.quizId
    },
    baseUrl() {
      return `https://storage.googleapis.com/exam-dangal-storage/questions/${this.question.id}`
    },
    processedQuestion() {
      let base = this.baseUrl
      let q = Object.assign({}, this.question)
      q.question = replaceImageNameWithURL(q.question||'', base)
      q.a = replaceImageNameWithURL(q.a||'', base)
      q.b = replaceImageNameWithURL(q.b||'', base)
      q.c = replaceImageNameWithURL(q.c||'', base)
      q.d = replaceImageNameWithURL(q.d||'', base)
      q.explanation = replaceImageNameWithURL(q.explanation||'', base)
      return q
    },
    
  },
  watch: {
    processedQuestion: {
      handler() {
        this.isAnswerVisible = false
        setTimeout(()=> {
          try{
              window.MathJax.typeset();
          }catch(e){console.log(e)}
        }, 50)
      }
    }
  },
  methods: {
    ...mapActions({
      computeScore: 'flashcard/computeScore',
      getNextQuestion: 'flashcard/getNextQuestion'
    }),
    knewAnswer() {
      this.computeScore({question_id: this.current, didKnew: true} )
      this.next()
    },
    didNotKnowAnswer() {
      this.computeScore({question_id: this.current, didKnew: false} )
      this.next()
    },
    async next() {
      let next_id = await this.getNextQuestion()
      if(next_id) { 
        this.$router.replace(`/flashcard/running/${this.id}/?q=${next_id}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.question-item {
  margin: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .flip-enter-active,
  .flip-leave-active {
    transition: transform 0.5s ease, opacity 0.5s ease;
  }

  .flip-enter-from {
    opacity: 0;
    position: absolute;
    transform: translateY(30px);
  }
  .flip-leave-to {
    opacity: 0;
    transform: translateY(30px);
    position: absolute;
  }
  .question-holder, .answer-holder {
    display: flex;
    flex-direction: column;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    text-align: justify;
    overflow: hidden;

    p {
      padding: 25px 10px;
    }
    ion-button {
      margin: 0;
    }
  }
  p {
    margin: 0;
  }
  span.subtitle {
    font-size: 0.7em;
  }
}
</style>