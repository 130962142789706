<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/"></ion-back-button>
        </ion-buttons>
        <ion-title>{{itemDetail.name}}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <overlay-loader v-if="isLoading" />
      <div v-else>
        <flashcard-list v-if="isFlashcardVisible" />
        <quiz-list v-if="isQuizVisible" />
        <exam-list v-if="isExamVisible" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton
} from "@ionic/vue";
import { pin, walk, warning, wifi, wine, ellipsisHorizontalSharp, arrowForward } from 'ionicons/icons';
import { mapActions, mapGetters } from 'vuex';
import OverlayLoader from '../../components/OverlayLoader.vue';
import FlashcardList from './FlashcardList.vue';
import QuizList from './QuizList.vue';
import ExamList from './ExamList.vue';

export default {
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButtons,
    IonBackButton,
    OverlayLoader,
    FlashcardList,
    QuizList,
    ExamList
  },
  setup() {
    return {
      pin, walk, warning, wifi, wine, ellipsisHorizontalSharp, arrowForward
    };
  },
  data() {
    return {
      itemDetail: {resources: []},
    }
  },
  computed: {
    ...mapGetters(['bundles', 'isBundlesLoading']),
    id() {
      return this.$route.params.topicId
    },
    isExamVisible() {
      return this.itemDetail.resources?.includes('exam')
    },
    isFlashcardVisible() {
      return this.itemDetail.resources?.includes('flashcard')
    },
    isQuizVisible() {
      return this.itemDetail.resources?.includes('quiz')
    },
    isLoading() {
      if(this.itemDetail.name) {
        return false
      }
      return true
    }
  },
  watch: {
    bundles: {
      handler() {
        // console.log(this.bundles[0])
      }
    }
  },
  methods: {
    ...mapActions(['getItemDetail']),
  },
  async mounted() {
    let itemDetail = await this.getItemDetail({id: this.id})
    // console.log(itemDetail)
    
    this.itemDetail = itemDetail
  },
};
</script>

<style lang="scss" scoped>


.new-content {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #ff5e36;
  margin-top: 10px;
  margin-left: 4px;
  align-self: start;
}
</style>