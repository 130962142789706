

const THRESHOLD = 0.7
const LOWER_THRESHOLD = 0.1
export default {
  namespaced: true,
  state () {
    return {
      id: "",
      startedAt: 0,
      totalQuestions: 0,
      availableLanguages: [{
        name: 'Hindi',
        short: 'hi',
        id: '558d0fb7-729a-4bcb-87c1-3f2774487800'
      }, {
        name: 'English',
        short: 'en',
        id: '0f506717-ee80-44f1-b11b-0ddf15d8d559'
      }],
      language_id: '0f506717-ee80-44f1-b11b-0ddf15d8d559',
      sessions: [],
      actions: [],
      score: {}
    }
  },
  mutations: {
    SET_ID(state:any, {id, question_ids}:any) {
      console.log(state.sessions, state.id, id)
      if(state.id === id) {
        return 0
      }
      if(state.id) {
        // Backup session data
        state.actions = [...state.actions, {
          type: 'CHANGE',
          created_at: Date.now()
        }]
        state.sessions = state.sessions.filter((s:any)=> s.id !== state.id ) 
        state.sessions = [
          ...state.sessions,
          {
            startedAt: state.startedAt,
            totalQuestions: state.totalQuestions,
            id: state.id,
            score: JSON.parse(JSON.stringify(state.score)),
            actions: JSON.parse(JSON.stringify(state.actions))
          }
        ]
      }
      if(id) {
        // Create/Restore a new session
        const session = state.sessions.find((s:any)=> s.id == id)
        console.log(session)
        if(session) {
          state.id = session.id,
          state.score = session.score
          state.actions = session.actions
          state.startedAt = session.startedAt
          state.totalQuestions = session.totalQuestions
        }else{
          state.score = {}
          state.actions = []
          state.startedAt = Date.now()
        }
        state.actions = [...state.actions, {
          type: 'START_SESSION',
          created_at: Date.now(),
        }]
        // Make score
        for(const i in question_ids) {
          if(!state.score[question_ids[i]]) {
            state.score[question_ids[i]] = 0
          }
        }
        state.totalQuestions = Object.keys(state.score).length
      }
      console.log(state)
      state.id = id
    },
    CHANGE_LANGUAGE(state:any, language_id:string) {
      state.language_id = language_id
    },
    COMPUTE_SCORE(state:any, {question_id, didKnew}:any) {
      if(didKnew) {
        state.score[question_id] = Math.min(1, state.score[question_id] + 0.43)
      }else {
        state.score[question_id] = Math.max(0, state.score[question_id] - 0.15)
      }
    }
  },
  actions: {
    setId({ commit }:any, {id, question_ids}:any) {
      commit('SET_ID', {id, question_ids})
    },
    changeLanguage({commit}:any, {language_id}:any) {
      commit('CHANGE_LANGUAGE', language_id)
    },
    computeScore({commit}:any, {question_id, didKnew}: any) {
      commit('COMPUTE_SCORE', {question_id, didKnew})
    },
    getNextQuestion({state}:any) {
      const question_to_be_shown = Object.keys(state.score).filter(id=> state.score[id] < THRESHOLD)
      const cummulative_probablity = question_to_be_shown.reduce((acc, id)=> {
        return [...acc, acc[acc.length - 1] + (1 - state.score[id]) + 0.000001]
      }, [0])
      const sum = cummulative_probablity[cummulative_probablity.length - 1]
      const normalised_cummulative_probablity = cummulative_probablity.map(p=> p/sum)

      const n = Math.random()
      const selected_index = normalised_cummulative_probablity.findIndex(p=> p>n) - 1
      return question_to_be_shown[selected_index]
    }
  },
  getters: {
    mastered(state:any) {
      return Object.keys(state.score).filter(id=> state.score[id] >= THRESHOLD).length
    },
    revising(state:any) {
      return Object.keys(state.score).filter(id=> state.score[id] < THRESHOLD && state.score[id] > LOWER_THRESHOLD).length
    },
    learning(state:any) {
      return Object.keys(state.score).filter(id=> state.score[id] < LOWER_THRESHOLD).length
    },
    completedFlashcards(state:any) {
      console.log(state.sessions)
      return []
    }
  },
}