import Blockly from 'blockly';
import {javascriptGenerator} from 'blockly/javascript';



Blockly.Blocks['get_documents'] = {
  init: function() {
    this.jsonInit({
      "type": "get_documents",
      "message0": "docs from collection %1 %2 where %3 %4 %5",
      "args0": [
        {
          "type": "field_input",
          "name": "collection_name",
          "text": "collection name",
        },
        {
          "type": "input_dummy",
        },
        {
          "type": "field_input",
          "name": "field_name",
          "text": "field name",
        },
        {
          "type": "field_dropdown",
          "name": "condition",
          "options": [["==","=="], ["!=","!="]]
        },
        {
          "type": "input_value",
          "name": "value",
        },
      ],
      "inputsInline": true,
      "output": "Array",
      "colour": 230,
      "tooltip": "tool tip",
      "helpUrl": "help url"
    })
  }
};

Blockly.Blocks['get_document'] = {
  init: function() {
    this.jsonInit({
      "type": "get_document",
      "message0": "1 doc from collection %1 with id %2",
      "args0": [
        {
          "type": "field_input",
          "name": "collection_name",
          "text": "collection name",
        },
        {
          "type": "input_value",
          "name": "id",
          "text": "doc id",
        },
      ],
      "inputsInline": true,
      "output": "Array",
      "colour": 230,
      "tooltip": "tool tip",
      "helpUrl": "help url"
    })
  }
};

Blockly.Blocks['geo_point'] = {
  init: function() {
    this.jsonInit({
      "type": "geo_point",
      "message0": "Geo point lat: %1, lng: %2",
      "args0": [
        {
          "type": "input_value",
          "name": "lat",
          "text": "0.0",
        },
        {
          "type": "input_value",
          "name": "lng",
          "text": "0.0",
        },
      ],
      "inputsInline": true,
      "output": null,
      "colour": 230,
      "tooltip": "tool tip",
      "helpUrl": "help url"
    })
  }
};

Blockly.Blocks['create_document'] = {
  init: function() {
    this.jsonInit({
      "type": "create_document",
      "message0": "Create a doc in collection %1 with data = %2",
      "args0": [
        {
          "type": "field_input",
          "name": "collection_name",
          "text": "collection name",
        },
        {
          "type": "input_value",
          "name": "object",
          "text": "Object",
        },
      ],
      "inputsInline": true,
      "output": "Array",
      "colour": 230,
      "tooltip": "tool tip",
      "helpUrl": "help url"
    })
  }
};
Blockly.Blocks['update_document'] = {
  init: function() {
    this.jsonInit({
      "type": "update_document",
      "message0": "Update doc in collection %1 with id %2 to %3",
      "args0": [
        {
          "type": "field_input",
          "name": "collection_name",
          "text": "collection name",
        },
        {
          "type": "input_value",
          "name": "id",
          "text": "Id",
        },
        {
          "type": "input_value",
          "name": "object",
          "text": "Object",
        },
      ],
      "inputsInline": true,
      "output": "Array",
      "colour": 230,
      "tooltip": "tool tip",
      "helpUrl": "help url"
    })
  }
};

javascriptGenerator['get_documents'] = function(block) {
  var collection_name = block.getFieldValue('collection_name');
  var field_name = block.getFieldValue('field_name');
  const condition = block.getFieldValue('condition');
  let value = javascriptGenerator.valueToCode(block, 'value', javascriptGenerator.ORDER_ATOMIC);
  console.log(field_name, condition, value)
  
  let query = ''
  if(field_name) {
    query = `\n.where('${field_name}', '${condition}', ${value})`
  }
  var code = `await (async () => {
                let snap = await admin.firestore()
                      .collection('${collection_name}')${query}.get()
                let data = []
                snap.forEach(doc => {
                  data.push({id: doc.id, ...doc.data()})
                });
                return data
              })()`;
  return [code, javascriptGenerator.ORDER_ATOMIC];
};


javascriptGenerator['geo_point'] = function(block) {
  var lat = javascriptGenerator.valueToCode(block, 'lat', javascriptGenerator.ORDER_ATOMIC);
  var lng = javascriptGenerator.valueToCode(block, 'lng', javascriptGenerator.ORDER_ATOMIC);
  // TODO: Assemble JavaScript into code variable.
  var code = `new admin.firestore.GeoPoint(${lat}, ${lng})`;
  return [code, javascriptGenerator.ORDER_ATOMIC];
};

javascriptGenerator['get_document'] = function(block) {
  var collection_name = block.getFieldValue('collection_name');
  let id = javascriptGenerator.valueToCode(block, 'id', javascriptGenerator.ORDER_ATOMIC);
  // TODO: Assemble JavaScript into code variable.
  var code = `await (async () => {
                let snap = await admin.firestore()
                      .collection(\`${collection_name}/\${${id}}\`).get()
                return {id: snap.id, ...snap.data()}
              })()`;
  return [code, javascriptGenerator.ORDER_ATOMIC];
};

javascriptGenerator['create_document'] = function(block) {
  var collection_name = block.getFieldValue('collection_name');
  let object = javascriptGenerator.valueToCode(block, 'object', javascriptGenerator.ORDER_ATOMIC);
  // TODO: Assemble JavaScript into code variable.
  var code = `await (async () => {
                let res = await admin.firestore()
                      .collection('${collection_name}').add(${object})
                return {id: res.id}
              })()`;
  return [code, javascriptGenerator.ORDER_ATOMIC];
};

javascriptGenerator['update_document'] = function(block) {
  var collection_name = block.getFieldValue('collection_name');
  let id = javascriptGenerator.valueToCode(block, 'id', javascriptGenerator.ORDER_ATOMIC);
  let object = javascriptGenerator.valueToCode(block, 'object', javascriptGenerator.ORDER_ATOMIC);
  // TODO: Assemble JavaScript into code variable.
  var code = `await (async () => {
                let res = await admin.firestore()
                      .collection(\`${collection_name}/\${${id}}\`).update(${object})
                return {id: res.id}
              })()`;
  return [code, javascriptGenerator.ORDER_ATOMIC];
};

