import gql from "graphql-tag";

export const GET_SUBSCRIPTIONS = gql`
  query{
    subscription{
      id
      title
      subtitle
      price
      duration
      description
    }
  }

`
