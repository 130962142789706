<template>
  <ion-page key="ui">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :icon="close" default-href="/"></ion-back-button>
        </ion-buttons>
        <ion-title>{{quiz.title || 'Exam'}}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <nav-block-alert />
      <overlay-loader v-if="isBundleLoading" />
      <div :class="`question-header ${isRightSliderOpen?'':'full'} ${isMobile?'mobile':'desktop'}`">
        <div style="display: flex; flex-direction: row; align-item: center; z-index: 100">
          <span>Text Size:</span>
          <button @click="setTextSize({ textSize: Math.max(1, textSize - 1) })">A-</button>
          <button @click="setTextSize({ textSize: Math.min(6, textSize + 1) })">A+</button>
        </div>
        <div :class="`timer ${timer.min < 5 && timer.hour == 0? 'highlight':''}`">
          <span v-if="!isMobile">Time remaining </span>
          <span>
          {{pad(timer.hour)}}:{{pad(timer.min)}}:{{pad(timer.sec)}}
          </span>
        </div>
      </div>
      <question-item />
      <right-slider key="rs" :total="quiz.questions?.length || 0" :questions="quiz.questions" />
      <summary-modal
        v-model="isSummaryVisible" 
        @submit="submit" />
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-buttons v-if="isMobile" slot="start">
          <ion-button v-if="isBookmarked(question.id)" @click="removeBookmark({id: question.id})">
            <ion-icon size="large" :icon='bookmark' />
          </ion-button>
          <ion-button v-else @click="addBookmark({id: question.id})">
            <ion-icon size="large" :icon='bookmarkOutline' />
          </ion-button>
          <ion-button
            :disabled="!answers[question.id]"
            @click="clearAnswer({id: question.id})">
            <ion-icon size="large" :icon='removeCircleOutline' />
          </ion-button>
        </ion-buttons>
        <ion-buttons v-else slot="start">
          <ion-button color='primary' fill='solid' style="min-width: 200px"
            v-if="isBookmarked(question.id)" @click="removeBookmark({id: question.id})">
            Unmark
          </ion-button>
          <ion-button v-else style="min-width: 200px"
            color='primary' fill='solid' @click="addBookmark({id: question.id})">
            Mark for review
          </ion-button>
          <ion-button
            color='primary' fill='solid'
            :disabled="!answers[question.id]"
            @click="clearAnswer({id: question.id})">
            Clear your answer
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button
            color='primary' fill='solid'
            :disabled="current >= totalQuestions"
            @click="async()=> {
                $router.replace(`/exam/running/${id}/?q=${Math.min(current+1, totalQuestions)}&s=${sessionId}`)
            }">Save and next</ion-button>
          <ion-button
            color='danger' fill='solid'
            @click="isSummaryVisible = true">Submit</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonLabel,
  IonAvatar,
  IonRippleEffect,
  IonBackButton,
  IonFooter,
  IonIcon,
  alertController,
  isPlatform,
  IonRouterOutlet,
} from "@ionic/vue";
import { close, bookmark, bookmarkOutline, removeCircleOutline } from 'ionicons/icons';
import { mapGetters, mapActions, mapState} from 'vuex'
import NavBlockAlert from '../../components/NavBlockAlert.vue';
import OverlayLoader from '../../components/OverlayLoader.vue';
import QuestionItem from './QuestionItem.vue';
import {createConfirmationAlert} from '../../composables/alert'
import RightSlider from './RightSlider.vue';
import SummaryModal from './SummaryModal.vue';

function pad(v, n=2){
	v= v + ''
	return v.length >= n?v: new Array(n - v.length + 1).join('0') + v
}
export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    // IonLabel,
    // IonAvatar,
    // IonRippleEffect,
    IonBackButton,
    IonFooter,
    IonIcon,
    NavBlockAlert,
    OverlayLoader,
    QuestionItem,
    RightSlider,
    SummaryModal,
    // IonRouterOutlet,
  },
  setup() {
    return {
      pad,
      close, bookmark, bookmarkOutline, removeCircleOutline,
      createConfirmationAlert
    };
  },
  data() {
    return {
      isMobile: isPlatform('mobile'),
      isSummaryVisible: false,
      showContent: false,
			timer: {
				hour:0, min:0, sec:0, total:0
			},
    }
  },
  computed: {
    ...mapGetters(['quiz', 'isBundleLoading']),
    ...mapState({
      isRightSliderOpen: state=> state.exam.isRightSliderOpen,
      bookmarks: state=> state.exam.bookmarks,
      answers: state=> state.exam.answers,
			availableLanguages: state => state.exam.availableLanguages,
			language_id: state => state.exam.language_id,
			currentSection: state => state.exam.currentSection,
			sections: state => state.exam.sections,
			sessionId: state => state.exam.session_id,
			textSize: state => state.exam.textSize,
      totalQuestions: state => state.exam.totalQuestions,
			startedAt: state => state.exam.startedAt,
			sessionExpired: state => state.exam.sessionExpired 
    }),
    id() {
      return this.$route.params.quizId
    },
    current() {
      return parseInt(this.$route.query.q || '1')
    },
    question() {
      return this.quiz.questions?.[this.current - 1] || {translations: [], tags: []}
    },
		timeLimit() {
			return this.quiz?.time_limit || 120
		},
  },
  watch: {
    // quiz: {
    //   handler() {
    //     console.log(13)
    //     this.setTotalQuestions({total: this.quiz.questions.length})
    //   },
    //   immediate: true
    // },
    // sessionExpired: {
    //   async handler() {
    //     console.log(this.$route)
        // if(this.sessionExpired && this.$route.params.sessionId) {
				// 	clearInterval(this.proc)
        //   const alert = await alertController
        //     .create({
        //       cssClass: 'my-custom-class',
        //       header: 'Session is expired',
        //       buttons: ['View summary'],
        //     });
        //   await alert.present();
        //   await alert.onDidDismiss();
        //   await this.toggleNavBlock({isBlocked: false})
        //   this.$router.replace(`/exam/analysis/${this.id}/${this.sessionId}`)
        // }
      // },
    //   immediate: true
    // },
		currentSection: {
			handler() {
        if( this.question.tags.find(t=> t.id === this.currentSection) ) {
          return
        }
        let indexOfFirstQuestionInSection = this.quiz.questions
                                  .findIndex(q=> q.tags.map(t=>t.id).includes(this.currentSection))
        if(indexOfFirstQuestionInSection < 0) {
          return
        }
        
        this.$router.replace(`/exam/running/${this.id}/?q=${indexOfFirstQuestionInSection + 1}&s=${this.sessionId}`)
			}
		},
    question: {
      handler() {
        if(!this.question) {
          return
        }
        const sectionId = this.question.tags.map(t=> t.id).find(id=> this.sections.map(s=> s.id).includes(id))
        if(!sectionId) {
          return
        }
        this.setSection({section_id: sectionId})
      }
    }
  },
  proc: null,
  async mounted() {
    console.log(this.id, this.sessionId)

    this.setTotalQuestions({total: this.quiz.questions.length})

    let sessionId = await this.setId({id: this.id, sessionId: this.sessionId})
    console.log(sessionId)
    this.$router.replace(`/exam/running/${this.id}/?q=${this.current}&s=${sessionId}`)

    this.toggleRightSlider(!isPlatform('mobile'))
    this.showContent = true
		this.proc = setInterval(()=>{
			let isExpired =  Math.floor(this.timeLimit*60 - (Date.now() - this.startedAt)/1000)< 0 

			if(isExpired) {
				this.submitSession()
			}
			this.computeTimeRemaining()
		}, 500)
  },
	unmounted() {
		clearInterval(this.proc)
	},
  methods: {
    ...mapActions(['toggleNavBlock', 'getBundle']),
    ...mapActions({
      setId: 'exam/setId',
      addBookmark: 'exam/addBookmark',
      removeBookmark: 'exam/removeBookmark',
      clearAnswer: 'exam/clearAnswer',
      finishExam: 'exam/finishExam',
      setTotalQuestions: 'exam/setTotalQuestions',
			setSection: 'exam/setSection',
      toggleRightSlider: 'exam/toggleRightSlider',
			submitSession: 'exam/submitSession',
			setTextSize: 'exam/setTextSize'
    }),
    isBookmarked(question_id) {
      return this.bookmarks.includes(question_id)
    },
		computeTimeRemaining() {
			// if(!this.data || this.data.status !== STATUS.INCOMPLETE){
			// 	return {hour: 0, min: 0, sec: 0, total: 0}
			// }
			let total = Math.floor(this.timeLimit*60 - (Date.now() - this.startedAt)/1000)
			let hour = total
			let sec = total % 60
			hour = Math.floor(hour/60)
			let min = hour % 60
			hour = Math.floor(hour/60)
			this.timer = {hour, min, sec, total}
		},
    async submit() {
      if(await this.createConfirmationAlert('Confirm submition?')) {
        this.isSummaryVisible = false
        let sessionId = this.sessionId
        await this.finishExam()
        await this.toggleNavBlock({isBlocked: false})
        this.$router.replace({ name: 'exam_analysis', params: { id: this.id, sessionId } })
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.question-header {
  border-top: 1px solid rgba(0,0,10,0.8);
  border-bottom: 1px solid rgba(0,0,10,0.8);
  background-color: #4e85c5;
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  color: white;
	transition: width 0.4s ease-in;


  &.desktop {
    width: 75vw;
  }
  &.full.desktop {
    width: 100vw;
  }

  .timer {
    padding: 2px 10px;
    border-radius: 3px;
    font-size: 0.9em;

    &.highlight {
      animation: blink-animation 0.25s 10 ease-in-out;
      color: white;
      background-color:  red;
    }
    @keyframes blink-animation {
      33%{
        background-color:  red;
      }
      50%{
        background-color:  #adadb8;;
      }
      100%{
        background-color:  red;;
      }
    }
  }
}

</style>
