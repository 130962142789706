
import {
  IonIcon,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle
} from "@ionic/vue";
import {
  pin,
  star
} from "ionicons/icons";

export default {
  components: {
    IonIcon,
    IonToolbar,
    IonButtons,
    IonButton,
    IonTitle
  },
  setup() {
    return {
      pin, star
    }
  }
};
