<template>
  <div class="code-editor">
    <div class="action-holder" v-if="showAction">
      <ion-button @click="$emit('run')" fill="clear" color="light"><ion-icon :icon="playCircleSharp" /></ion-button>
    </div>
    <codemirror
      v-model="code"
      placeholder="Code goes here..."
      :style="{ maxHeight: '400px', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', overflow: 'hidden', fontSize: '16px', paddingTop: '10px', paddingBottom: '10px', marginBottom: '15px' }"
      :autofocus="false"
      :indent-with-tab="true"
      :tab-size="2"
      :extensions="extensions"
      :root="root"
      @change="$emit('change')"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import {
  IonButton,
  IonIcon
} from "@ionic/vue";
import { playCircleSharp } from "ionicons/icons";
import { Codemirror } from 'vue-codemirror'
import { javascript } from '@codemirror/lang-javascript'
import { oneDark } from '@codemirror/theme-one-dark'

export default defineComponent({
  components: {
    IonButton,
    IonIcon,
    Codemirror
  },
  setup() {
    const extensions = [oneDark, javascript()]

    return {
      extensions,
      log: console.log,
      root: document,
      playCircleSharp
    }
  },
  props: {
    modelValue: String,
    showAction: Boolean
  },
  data() {
    return {
      code: `console.log('Hello, world!')`
    }
  },
  watch: {
    code: {
      handler() {
        this.$emit('update:modelValue', this.code)
      }
    },
    modelValue: {
      handler() {
        this.code = this.modelValue
      },
      immediate: true
    }
  },
  methods: {
    onChange(e1, e2) {
      console.log(e1, e2)
    }
  }
})
</script>

<style lang="scss" scoped>
.code-editor {
  position: relative;
}
.action-holder {
  z-index: 1000;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: #282c34;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px gray solid;
}
</style>
