<template>
  <div :class="`right-slider ${isRightSliderOpen?'visible':''} ${isMobile?'':'desktop'}`">
    <div @click="toggleRightSlider(!isRightSliderOpen)" class='switch'>
      <ion-icon :name="isRightSliderOpen?'chevron-forward':'chevron-back'" />
    </div>
    <div class='question-legend-holder'>
      <div class='profile'>
        <ion-icon :icon='person' /><span>{{userData?.profile.name || 'Name'}}</span>
      </div>
      <div class='legend-item'>
        <div class='shape green'>{{attempted.length}}</div><span>Attempted</span>
      </div>
      <div class='legend-item'>
        <div class='shape red'>{{total - attempted.length}}</div><span>Not Answered</span>
      </div>
      <div class='legend-item'>
        <div>{{total - visited.length}}</div><span>Not Visited</span>
      </div>
      <div class='legend-item'>
        <div class='orange'>{{bookmarks.length}}</div><span>Marked</span>
      </div>
      <div class='legend-item'>
        <div class='green orange'>{{markedAndAnswered.length}}</div><span>Marked & Answered</span>
      </div>
    </div>
    <div class='question-widget'>
      <b style="background: #4e85c5; padding: 5px 15px; color: white">
        {{(sections.find(s=>s.id == selectedSection)|| {}).name}}</b>
      <span style="padding: 5px">Choose a question:</span>
      <div class='question-tag-holder'>
        <router-link 
          v-for="q, i in questions"
          :to="`/exam/running/${id}/?q=${i+1}&s=${sessionId}`"
          replace
          :key="i" :class="tagClass(q, i+1)">{{i+1}}</router-link>
      </div>
      <ion-button fill="clear" style="margin-top: 30px; max-width: 200px" color='danger' @click="isModalOpen=true" >
        <ion-icon slot="start" :icon="warning"/>
        Report error
      </ion-button>
    </div>
    <report-error
      v-model:isOpen="isModalOpen"
      itemType="QUESTION"
      :itemId="currentQuestion.id"
      description="If you find anything wrong with the questions, please report. This help us maintain the quality with our limited resources."
      buttonText="Report question" />
  </div>
</template>

<script>
import {
  IonIcon,
	isPlatform,
  IonButton
} from "@ionic/vue";
import { person, warning } from 'ionicons/icons';
import {mapState, mapActions, mapGetters} from 'vuex'
import ReportError from '../../components/ReportError.vue';

export default {
  components: {
    IonIcon,
    ReportError,
    IonButton
  },
  setup() {
    return {
      person,
      warning
    };
  },
  props: {
    questions: {
      type: Array,
      default: ()=> []
    },
    total: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      isMobile: isPlatform('mobile'),
      isModalOpen: false,
			state: {
				attempted: 3,
				answered: 2,
				visited: 10,
				marked: 4,
				markedAndAnswered: 4
			},
			userData: {profile: {name: 'User'}},
			sections: [
				{
					name: "English",
					id: 'en',
				}, {
					name: 'Math',
					id: 'ma'
				}
			],
      selectedSection: '',
    }
  },
  computed: {
    ...mapGetters({
      attempted: 'exam/attempted',
      markedAndAnswered: 'exam/markedAndAnswered'
    }),
    ...mapState({
      isRightSliderOpen: state=> state.exam.isRightSliderOpen,
      bookmarks: state=> state.exam.bookmarks,
      visited: state=> state.exam.visited,
      answers: state=> state.exam.answers,
      sessionId: state=> state.exam.session_id
    }),
    id() {
      return this.$route.params.quizId
    },
    current() {
      return this.$route.query.q || '1'
    },
    currentQuestion() {
      return this.questions[parseInt(this.$route.query.q) - 1] || {}
    }
  },
  methods: {
    ...mapActions({
      toggleRightSlider: 'exam/toggleRightSlider'
    }),
    isBookmarked(question_id) {
      return this.bookmarks.includes(question_id)
    },
    isVisited(question_id) {
      return this.visited.includes(question_id)
    },
    isAnswered(question_id) {
      return this.attempted.includes(question_id)
    },
    tagClass(question, n) {
      let color = ''
      if(this.isVisited(question.id)){
        color = 'red shape'
      }
      if(this.isAnswered(question.id)){
        color = 'green shape'
      }
      if(this.isBookmarked(question.id)){
        color = 'orange'
        if(this.isAnswered(question.id)){
          color = 'orange green'
        }
      }
      return `${n == this.current?'current':''} ${color}`
    }
  }
}
</script>

<style lang="scss" scoped>

.right-slider {
  position: absolute;
  top: 0;
  right: -80.5vw;
  display: flex;
  flex-direction: column;
  width: 80vw;
  min-width: 200px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  height: calc(100% - 2px);
  transition: right 0.4s ease-in, box-shadow 0.4s ease-in,;
	box-shadow: 0px 0 10vw -10px rgba(100,100,100, 0.3);
  background: white;
  

  &.visible {
    right: 0;
  }
  &.desktop {
    width: 25vw;
    right: -25.5vw;
    &.visible {
      right: 0;
    }
  }

  .switch {
    position: absolute;
    top: calc(50% - 10px);
    left: -10px;
    cursor: pointer;
    transform: translateX(-100%);
    font-size: 2em;
    color: white;
    background: #000;
    display: flex;
    padding: 2px;
    border-radius: 18px;
    
  }

  .question-widget{
    flex: 1.0;
    background-color: #e5f6fd;
    font-size: 0.9em;
    flex-direction: column;
    display: flex;
  }
  .question-tag-holder{
    overflow-y: scroll;
    padding: 5px;
    max-height: 30vh;
  }

  .question-tag-holder, .question-legend-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &>a, .legend-item>div{
      position: relative;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      margin-right: 5px;
      margin-bottom: 8px;
      background: linear-gradient(180deg, #dcdcdc, #dbdbdb, #dadada, #d7d7d7, #d4d4d4, #d1d1d1, #cecdcd, #cacaca, #c8c7c7, #c5c4c4, #c4c3c3, #c3c2c2);
      text-align: center;
      text-decoration: none;
      color: black;

      &.shape::before{
        content: " ";
        background: #e5f6fd;
        width: inherit;
        height: inherit;
        position: absolute;
        transform: rotateZ(45deg);
        left: -80%;
      }
      &.shape::after{
        content: " ";
        background: #e5f6fd;
        width: inherit;
        height: inherit;
        position: absolute;
        transform: rotateZ(45deg);
        right: -80%;
      }
      &.current {
        // border-radius: 50px;
        // border: 2px solid black;
        // padding: 0;
      }
      &.red {
        background: #ee0000;
        color: white;
        &::before{
          top: 60%;
        }
        &::after{
          top: 60%;
        }
      }
      &.green {
        background: #078707;
        color: white;
        &::before{
          bottom: 60%;
        }
        &::after{
          bottom: 60%;
        }
      }
      &.orange {
        background: #782dd2;
        color: white;
        position: relative;
        border-radius: 50px;
        &.green::after{
          content: '\2713';
          color: #078707;
          font-weight: bold;
          font-size: 1.5em;
          position: absolute;
          bottom: 3px;
          right: 0px;
          top: -5px;
        }
      }
    }
  }
  .question-legend-holder {
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
    justify-content: space-between;
    padding: 10px;
    margin-top: unset;
    .profile{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      
      .react-icons {
        margin: unset;
      }
    }
    .legend-item{
      width: 50%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      &>div{
        width: 40px;
        margin: 0;
        margin-right: 10px;

        &.red::before, &.red::after, &.green::before, &.green::after{
          background-color: white !important;
        }
        &.orange::before, &.orange::after{
          background-color: transparent !important;
        }
      }
      &:last-child{
        width: 100%;
        margin-bottom: unset;
      }
    }
  }
}
</style>