<template>
  <div>
    <ion-title class="ion-padding-top">Quizzes</ion-title>
    <div v-if="quizzes.length == 0" style="margin-top: 20px; justify-content: center; display: flex;">
      <ion-spinner v-if="isLoading" />
      <span v-else>No content</span>
    </div>
    <div class="folder-holder">
      <div v-for="quiz in quizzes" :key="quiz.id">
        <router-link
          v-if="!quiz._is_coming_soon"
          :to="`/quiz/start/${quiz.id}`"
          class="ion-activatable">
          <ion-avatar slot="start">
            <img src="/assets/icon/quiz.png" />
          </ion-avatar>
          <ion-label>
            <h3>{{quiz.title}}</h3>
            <p>{{quiz.questions_aggregate.aggregate.count}} questions | easy</p>
          </ion-label>
          <ion-ripple-effect></ion-ripple-effect>
        </router-link>
        <div v-if="quiz._is_coming_soon" style="opacity: 0.5">
          <ion-avatar slot="start">
            <img src="/assets/icon/quiz.png" />
          </ion-avatar>
          <ion-label>
            <h3>{{quiz.title}}</h3>
            <p>Releases on {{moment(quiz.publish_at).format('MMM, DD')}} at {{moment(quiz.publish_at).format('hh: mm A')}}</p>
          </ion-label>
          <ion-ripple-effect></ion-ripple-effect>
        </div>
      </div>
      <ion-button
        v-if="!shouldShowAll && !isLessThanLimit"
        @click="shouldShowAll = true">
        {{allQuizzes.length - limit}} more. Show all
      </ion-button>
      <ion-button
        v-if="shouldShowAll && !isLessThanLimit"
        @click="shouldShowAll = false">
        Show less
      </ion-button>
    </div>
  </div>
</template>

<script>

import {
  IonTitle,
  IonLabel,
  IonAvatar,
  IonRippleEffect,
  IonSpinner,
  IonButton
} from "@ionic/vue";
import {mapState, mapActions} from 'vuex'

export default {
  components: {
    IonTitle,
    IonLabel,
    IonAvatar,
    IonRippleEffect,
    IonSpinner,
    IonButton
  },
  data() {
    return {
      shouldShowAll: false,
      limit: 3,
      // quizs: []
    }
  },
  computed: {
    ...mapState({
      _quizzes: state=> state.content.bundlesByTag,
      loadingStatus: state => state.content.loadingStatus
    }),
    isLoading() {
      return this.loadingStatus[`${this.id}_5`] || false
    },
    allQuizzes() {
      let quizzes =this._quizzes?.[`${this.id}_5`] || []
      quizzes = quizzes.map(e=>{
        let _is_coming_soon =  Date.parse(e.publish_at) > Date.now()
        return {...e, _is_coming_soon}
      })
      quizzes.sort((a, b) => a.publish_at.localeCompare(b.publish_at))
      return quizzes
    },
    quizzes() {
      if(this.shouldShowAll) {
        return this.allQuizzes
      }
      return this.allQuizzes.filter((f, i) => i < this.limit)
    },
    isLessThanLimit() {
      return this.allQuizzes.length < this.limit
    },
    id() {
      return this.$route.params.topicId
    },
  },
  async mounted() {
    this.getBundlesByTag({tag_id: this.id, type: 5})
  },
  methods: {
    ...mapActions(['getBundlesByTag']),
  },
}
</script>

<style lang="scss" scoped>
.folder-holder {
  display: grid;
  grid-template-columns: 1fr;
  padding: 10px;
  gap: 5px;
  &>div>a, &>div>div  {
    text-transform: unset;
    text-decoration: none;
    height: unset;
    --background: unset;
    color: unset;
    align-items: center;
    display: flex;
    flex: 1;
    overflow: hidden;
    position: relative;
    border-radius: 5px;

    p{
      font-size: 0.7em;
    }
  }
  ion-avatar {
    --border-radius: unset;
    img {
      padding: 10px;
    }
  }
}

</style>