

export default {
  namespaced: true,
  state () {
    return {
      currentProject: {},
      projects: [],
      isLoading: true,
    }
  },
  mutations: {
    async SET_PROJECT(state:any, data:any) {
      state.currentProject = data
    },
    async SET_PROJECTS(state:any, data:any[]) {
      state.projects = data
    },
    async SET_LOADING(state:any, data:boolean) {
      state.isLoading = data
    },
  },
  actions: {
    async setProject({ commit }:any, project:any) {
      commit('SET_PROJECT', project)
    },
    async setProjects({ commit }:any, projects:any) {
      commit('SET_PROJECTS', projects)
    },
  },
}