

export default {
  namespaced: true,
  state () {
    return {
      user: null,
      claims:  null,
      token: null,
      isLoading: true,
      isAuth: false
    }
  },
  mutations: {
    async SET_USER(state:any, data:any) {
      if(!data) {
        state.user = null
        state.claims = null
        state.isAuth = false
        state.isLoading = false
        return
      }
      state.user = JSON.parse(JSON.stringify(data.user))
      state.claims = data.token.claims
      state.token = data.token.token
      window.localStorage.setItem('firebase-token', data.token.token);
      state.isAuth = true
      state.isLoading = false
    },
    async SET_LOADING(state:any, data:boolean) {
      state.isLoading = data
    },
  },
  actions: {
    async setUser({ commit }:any, user:any) {
      if(!user) {
        commit('SET_USER', user)
      }else {
        const claimGetter:any = async ()=> {
          await user.getIdToken(true);
          const token = await user.getIdTokenResult()
          // if(!token.claims['https://hasura.io/jwt/claims']) {
          //   await user.getIdToken(true);
          //   return claimGetter()
          // }
          return token
        }
        const token = await claimGetter()
        commit('SET_USER', {user, token})
        setInterval(async()=>{
          const token = await claimGetter()
          commit('SET_USER', {user, token})
        }, 5*60*1000)
      }
    },
  },
  getters: {
    user(state:any) {
      return state.user
    },
    claims(state:any) {
      return state.claims
    },
    token(state:any) {
      return state.token
    },
    role(state:any) {
      return state.claims?.['https://hasura.io/jwt/claims']?.['x-hasura-default-role']
    },
    userId(state:any) {
      return state.claims?.['https://hasura.io/jwt/claims']?.['x-hasura-user-id']
    }
  },
}