import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, {
        class: _normalizeClass(`home-tabs-holder ${$setup.isDesktop?'desktop':''}`)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet, { mode: "md" }),
          _createVNode(_component_ion_tab_bar, {
            slot: "bottom",
            color: $setup.isDesktop?'dark':null
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "home",
                href: "/home"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: $setup.homeSharp }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Home")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "practice",
                href: "/practice"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: $setup.barbell }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Practice")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "learn",
                href: "/learn"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: $setup.trendingUpOutline }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Learn")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "revise",
                href: "/revise"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: $setup.albums }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Revise")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "performance",
                href: "/performance"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: $setup.analyticsSharp }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Performance")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["color"])
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }))
}