<template>
  <ion-modal 
    :is-open="isOpen"
    :breakpoints="[0.6, 0.9]"
    :initialBreakpoint="0.6"
    @didDismiss="$emit('update:isOpen', false)">
    <ion-content>
      <ion-header>
        <ion-toolbar>
          <ion-title>Report an error</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="$emit('update:isOpen', false)">
              <ion-icon name='close' />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <div style="padding: 10px; text-align: justify; display: flex; flex-direction: column; gap: 10px">
        <span>{{description}}</span>
        <ion-item>
          <ion-label position="floating">Problem description</ion-label>
          <ion-input v-model="desc"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Email</ion-label>
          <ion-input v-model="email"></ion-input>
        </ion-item>
        <ion-button @click="reportError" color='danger'>
          {{buttonText}}<ion-spinner v-if="isLoading"></ion-spinner></ion-button>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
  IonSpinner
} from "@ionic/vue";
import { close } from 'ionicons/icons';
import { CREATE_ERROR_REPORT } from '../graphql/common'

export default {
  components: {
    IonModal,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonButtons,
    IonIcon,
    IonItem,
    IonLabel,
    IonInput,
    IonSpinner
  },
  setup() {
    return {
      close
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ""
    },
    buttonText: {
      type: String,
      default: ""
    },
    itemId: {
      type: String,
      default: ''
    },
    itemType: {
      type: String,
      default: ''
    }
  },
  emits: ['update:isOpen'],
  data() {
    return {
      desc: "",
      email: "",
      isLoading: false
    }
  },
  methods: {
    async reportError() {
      const data = {
        description: this.desc,
        email: this.email,
        item_id: this.itemId,
        item_type: this.itemType
      }
      this.isLoading = true

      let res = await this.$apollo.mutate({
        mutation: CREATE_ERROR_REPORT,
        variables: {
          object: data
        }
      })
      this.isLoading = false
      setTimeout(()=>{
        this.$emit('update:isOpen', false)
      }, 100)
    }
  }
  
}
</script>
