<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :icon="close" default-href="/"></ion-back-button>
        </ion-buttons>
        <ion-title>{{quiz.title || 'Flashcard'}}</ion-title>
        <ion-buttons slot="end">
          <ion-button transparent
            :disabled="!isLanguageSwitchable"
            @click="switchLanguage"
            fill="clear"
            size='small'>
            <img style="height: 100%; padding: 5px" src="/assets/language.png"/>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <nav-block-alert />
      <ion-spinner v-if="isBundleLoading" />
      <!-- <overlay-loader v-if="isLoading" /> -->
      <card-item :question="question" />
      <!-- <question-item :question="question" /> -->
    </ion-content>
  </ion-page>
</template>

<script>

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonLabel,
  IonAvatar,
  IonRippleEffect,
  IonBackButton,
  IonFooter,
  IonIcon,
  IonSpinner
} from "@ionic/vue";
import { close } from 'ionicons/icons';
import { mapGetters, mapActions, mapState} from 'vuex'
import NavBlockAlert from '../../components/NavBlockAlert.vue';
import { GET_BUNDLE } from '../../graphql/bundle';
import OverlayLoader from '../../components/OverlayLoader.vue';
import {createConfirmationAlert} from '../../composables/alert'
import CardItem from './CardItem.vue';

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    // IonLabel,
    // IonAvatar,
    // IonRippleEffect,
    IonBackButton,
    NavBlockAlert,
    // OverlayLoader,
    CardItem,
    IonSpinner
  },
  setup() {
    return {
      close, 
      createConfirmationAlert
    };
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['quiz', 'isBundleLoading']),
    ...mapState({
      loadingStatus: state=> state.content.loadingStatus,
      language_id: state => state.flashcard.language_id
    }),
    id() {
      return this.$route.params.quizId
    },
    isLoading() {
      if(this.loadingStatus[this.id] == null) {
        return false
      }
      return this.loadingStatus[this.id]
    },
    current() {
      return this.$route.query.q
    },
    question() {
      if(!this.current){
        return {translations: []}
      }
      let q = this.quiz.questions.find(_q=> _q.id == this.current) || {translations: []}
      let tr = q.translations.find(tr=> tr.language_id == this.language_id)
      if(!tr) {
        return {}
      }
      let {question, explanation, a, b, c, d} = tr
      Object.assign(q, {question, explanation, a, b, c, d})
      return q
    },
    isLanguageSwitchable() {
      if(!this.current){
        return {translations: []}
      }
      let q = this.quiz.questions.find(_q=> _q.id == this.current) || {translations: []}
      return q.translations.length > 1
    }
  },
  watch: {
    isLoading: {
      handler() {
        if(this.isLoading) return
        let question_ids = this.quiz.questions.map(q=> q.id)
        this.setId({id: this.id, question_ids})
        this.goToRandomQuestion()
      },
      immediate: true
    }
  },
  async mounted() {
    this.getBundle({id: this.id})
  },
  methods: {
    ...mapActions(['toggleNavBlock', 'getBundle']),
    ...mapActions({
      setId: 'flashcard/setId',
      getNextQuestion: 'flashcard/getNextQuestion',
      changeLanguage: 'flashcard/changeLanguage',
    }),
    async goToRandomQuestion() {
      if(!this.current) {
        let next_id = await this.getNextQuestion()
        if(next_id) { 
          this.$router.replace(`/flashcard/running/${this.id}/?q=${next_id}`)
        }
      }
    },
    switchLanguage() {
      if(this.language_id == '558d0fb7-729a-4bcb-87c1-3f2774487800') {
        this.changeLanguage({language_id: '0f506717-ee80-44f1-b11b-0ddf15d8d559'})
      }else{
        this.changeLanguage({language_id: '558d0fb7-729a-4bcb-87c1-3f2774487800'})
      }
    }
  }
}
</script>
