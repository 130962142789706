
import 'swiper/scss';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue';
// import {
//   IonIcon,
// } from "@ionic/vue";
import {
  pin,
} from "ionicons/icons";

export default {
  components: {
    Swiper, SwiperSlide
  },
  setup() {
    return {
      pin
    }
  },
  props: {
    banners: {
      type: Array,
      default: ()=> ([
        {
          url: '/assets/slider/1.jpeg',
          name: "q 1"
        },
        {
          url: '/assets/slider/2.jpeg',
          name: "q 2"
        },
        {
          url: '/assets/slider/3.jpeg',
          name: "q 3"
        }
      ])
    }
  },
  data() {
    return {
      current: 0
    }
  }
};
