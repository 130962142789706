
const replaceImageNameWithURL = (text:string, base:string) => {
  if(!text) {
    return text
  }
  for (const match of text.matchAll(/\[\[\[([a-z.0-9-_]+)\]\]\]/gmi)) {
    const name = match[1]
    text = text.replaceAll(match[0], `<img style='max-width: 200px;' src="${base}/${name}" />`)
  }
  return text
}


export {
  replaceImageNameWithURL
}