<template>
  <div>
    <div class="blocklyDiv" ref="blocklyDiv" @change="onChange">
    </div>
    <xml ref="blocklyToolbox" style="display:none">
      <slot></slot>
    </xml>
  </div>
</template>

<script>
import Blockly from 'blockly';
export default {
  name: 'BlocklyComponent',
  props: ['options'],
  mounted() {
    var options = this.$props.options || {};
    if (!options.toolbox) {
      options.toolbox = this.$refs["blocklyToolbox"];
    }
    setTimeout(()=>{
      this.workspace = Blockly.inject(this.$refs["blocklyDiv"], options)
    }, 1000)
  },
  methods: {
    onChange(e) {
      console.log(e)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.blocklyDiv {
  z-index: 100;
  height: 100%;
  width: 100%;
  text-align: left;
}
</style>