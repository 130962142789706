<template>
  <ion-modal 
    :is-open="modelValue"
    :breakpoints="[0.8, 0.95]"
    :initialBreakpoint="0.8"
    @didDismiss="$emit('update:modelValue', false)">
    <ion-content>
      <ion-header>
        <ion-toolbar>
          <ion-title>Explanation</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="$emit('update:modelValue', false)">
              <ion-icon name='close' />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <div style="padding: 10px">
        <div class='question-item'>
          <p style="white-space: pre-wrap;" v-html="question.question"></p>
        </div>
        <div class="option-holder">
          <div :class="getClass('1')">
            <span class="label">A</span>
            <p style="white-space: pre-wrap;" v-html="question.a"></p>
          </div>
          <div :class="getClass('2')">
            <span class="label">B</span>
            <p style="white-space: pre-wrap;" v-html="question.b"></p>
          </div>
          <div :class="getClass('3')">
            <span class="label">C</span>
            <p style="white-space: pre-wrap;" v-html="question.c"></p>
          </div>
          <div :class="getClass('4')">
            <span class="label">D</span>
            <p style="white-space: pre-wrap;" v-html="question.d"></p>
          </div>
        </div>
        <div v-if="!selected" style="margin-top: 10px">
          This question was unanswered.
        </div>
        <div class='explanation'>
          <p style="white-space: pre-wrap;" v-html="question.explanation"></p>
        </div>
        <ion-item-divider />
        <ion-button fill="clear" style="margin-top: 10px; max-width: 200px" color='danger' @click="isModalOpen=true" >
          <ion-icon slot="start" :icon="warning"/>
          Report error
        </ion-button>
        <report-error
          v-model:isOpen="isModalOpen"
          itemType="QUESTION"
          :itemId="question.id"
          description="If you find anything wrong with the questions, please report. This help us maintain the quality with our limited resources."
          buttonText="Report question" />
      </div>
    </ion-content>
  </ion-modal>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonIcon,
  IonModal,
  IonItemDivider
} from "@ionic/vue";
import {mapState, mapGetters} from 'vuex'
import { warning } from 'ionicons/icons';
import ReportError from '../../components/ReportError.vue';

export default {
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonIcon,
    IonModal,
    IonItemDivider,
    ReportError,
  },
  setup() {
    const MAP = {1: 'a', 2:'b', 3: 'c', 4: 'd'}
    return {
      MAP, warning
    }
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    question: {
      type: Object,
      default: ()=>({})
    }
  },
  data() {
    return {
      isModalOpen: false
    }
  },
  watch: {
    question: {
      handler() {
				setTimeout(()=> {
					try{
							window.MathJax.typeset();
					}catch(e){console.log(e)}
				}, 50)
      }
    }
  },
  computed: {
    ...mapState({
      answers: state=> state.exam.answers,
    }),
    id() {
      return this.question.id
    },
    selected() {
      return this.answers[this.id]
    }
  },
  methods: {
    getClass(option) {
      if(this.question.answer == option) {
        return 'correct'
      }
      if(!this.selected) {
        return ''
      }
      if(this.selected == option && this.question.answer == option) {
        return 'correct'
      }
      if(this.selected == option && this.question.answer !== option) {
        return 'incorrect'
      }
      if(this.question.answer == option) {
        return 'correct'
      }
      return ''
    },
  }
}
</script>

<style lang="scss" scoped>
table {
  flex: 1;
  margin: 10px;
  border-collapse: separate;
  border: 1px solid #c4c4c4;
  td {

    &:first-child {
      background: #e5f6fd;
    }
    padding: 5px;
    border-bottom: 1px solid #e4e4e4;
  }
}
.question-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.option-holder {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  &>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    transition: background 0.2s linear;

    &.correct {
      background: #27ae60;
    }
    &.incorrect {
      background: #e74c3c;
    }
    &.selected {
      background: #27ae60;
    }

    span.label {
      padding: 5px;
      padding-right: 7px;
      background: #c4c4c4;
    }
  }
}
p{
  margin: 0;
}
.explanation {
  margin-top: 20px;
}
</style>
