import { Store } from "vuex"
import VuexPersistence from 'vuex-persist'
import theme from "./theme"
import recentlyAttempted from "./recentlyAttempted"
import content from "./content"
import exam from "./exam"
import quiz from "./quiz"
import flashcard from "./flashcard"
import subscription from "./subscription"
import user from "./user"
import account from "./account"

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: ({exam:{sections, ...exam}, user: {isLoading, isAuth, ...user}, ...rest }:any)=> {
    return {exam, user, ...rest}
  }
})

const store = new Store({
  plugins: [vuexLocal.plugin],
  modules: {
    theme,
    recentlyAttempted,
    content,
    exam,
    quiz,
    flashcard,
    subscription,
    user,
    account
  }
})

export default store
