
import {
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonCard,
  IonItem,
  IonLabel,
  IonIcon,
} from "@ionic/vue";
import {
  pin,
  walk,
  warning,
  wifi,
  wine,
  ellipsisHorizontalSharp,
  arrowForward,
} from "ionicons/icons";

export default {
  components: {
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonCard,
    IonItem,
    IonLabel,
    IonIcon
  },
  setup() {
    return {
      pin,
      walk,
      warning,
      wifi,
      wine,
      ellipsisHorizontalSharp,
      arrowForward,
    }
  },
};
